.About_page {
  /* background-color: #dddddd !important; */
  overflow: hidden;
}

.Box_about {
  height: 100% !important;
  width: 100% !important;
  scroll-margin-top: 100px;
  margin: auto !important;
  overflow: hidden;
  /* margin-bottom: 10px!important; */
  padding: 0px !important;
}

.build_box {
  background: linear-gradient(to top left, #52efda 0%, #8dffcc 100%) !important;
  text-align: center;
  width: 100% !important;
  padding: 80px 0px;
}

.about_heading {
  font-weight: 1000;
  font-size: 2.5rem;
  margin: auto !important;
  padding-top: 20px;
  font-family: "Spartan", sans-serif !important;
}

.about_para {
  margin: auto !important;
  font-size: 1.5rem;
  /* padding: 15px 200px; */
  padding: 15px 25%;
  font-family: "Open Sans", sans-serif !important;
}

.about_buttons {
  cursor: pointer !important;
  margin-left: 25% !important;
  margin-right: 25% !important;
  margin: 20px 0px;
}

.about_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 3.5rem;
  background-color: black;
  border: none;
  
  color: white;
  font-weight: 60;
  font-size: 1rem;
  font-family: "Spartan", sans-serif !important;
  font-stretch: condensed;
  cursor: pointer;
  margin-top: 0px;
  border-radius: 5px;
}

.referral_link_box {
  width: 100%;
  height: 3.5rem;
}

.know_your_score_welcome_box_left {
  background: linear-gradient(to top left, #52efda 0%, #8dffcc 100%) !important;
  text-align: center;
  /* width: 100%!important; */
  /* height: 100%; */
  padding: 20px 0px 0px;
  /* padding: 80px 0px; */
}

.know_your_score_welcome_box_right {
  background-color: #fff;
  /* width: 80%!important; */
  /* height: 100%; */
  padding: 20px 35px 30px;
}

.know_your_score_about_heading {
  font-weight: 1000;
  font-size: 2.5rem;
  margin: auto !important;
  padding-top: 20px;
  padding-bottom: 20px;
  font-family: "Spartan", sans-serif !important;
}

.image_upload_btn {
  width: 35%;
  margin-bottom: 2%;
}

.heading_right {
  padding-bottom: 0px;
}

.know_your_score_about_sub_heading {
  /* margin:auto!important; */
  font-size: 1rem;
  padding-top: 20px;
  font-family: "Spartan", sans-serif !important;
}
.about_the_test_heading {
  font-weight: 1000;
  font-size: 1.45rem;
  margin: auto !important;
  padding-top: 10px;
  font-family: "Spartan", sans-serif !important;
}
.about_test_btn {
  width: 100%;
  height: 60px;
  background: linear-gradient(to top left, #52efda 0%, #8dffcc 100%) !important;
  border: none;
  font-weight: 900;
  font-size: 2rem;
  font-family: "Spartan", sans-serif !important;
  font-stretch: ultra-expanded;
  cursor: pointer;
  padding: 0px 20px;
}
.know_your_score_para {
  font-size: 1rem !important;
  min-height: 90px !important;
  margin-bottom: 15px !important;
}

.know_your_score_para_small {
  font-size: 0.8rem !important;
  min-height: 90px !important;
  margin-bottom: 15px !important;
}

.questionaire_questions_heading {
  font-weight: 1000;
  font-size: 1.5rem;
  font-family: "Spartan", sans-serif !important;
}
.questionaire_btn {
  width: 80%;
  height: 30px;
  background: linear-gradient(to top left, #52efda 0%, #8dffcc 100%) !important;
  border: none;
  font-weight: 900;
  font-size: 1.2rem;
  font-family: "Spartan", sans-serif !important;
  font-stretch: ultra-expanded;
  cursor: pointer;
  padding: 0px 20px;
}

.questionaire_selector {
  margin-bottom: 2%;
}

.member_demo {
  padding-top: 20px;
}

.apply_button {
  cursor: pointer;
  margin: 20px 10px;
}

.apply_btn {
  width: 100%;
  height: 34px;
  background-color: #befaf2;
  border: none;
  font-weight: 900;
  font-size: 1rem;
  font-family: "Spartan", sans-serif !important;
  font-stretch: ultra-expanded;
  cursor: pointer;
}

.event_demo {
  padding: 50px 0px;
}

.partnership_demo {
  padding: 80px 40px;
}

.testimonials_demo {
  padding: 80px 40px;
  padding-bottom: 0px !important;
}

.testimonial_head {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.test_apply {
  cursor: pointer;
  margin: 20px 10px;
}

.test_apply_btn {
  width: 100%;
  height: 60px;
  background: linear-gradient(to top left, #52efda 0%, #8dffcc 100%) !important;
  border: none;
  font-weight: 900;
  font-size: 1rem;
  font-family: "Spartan", sans-serif !important;
  font-stretch: ultra-expanded;
  cursor: pointer;
  padding: 0px 20px;
  border-radius: 4px;
}

.upload_image_btn {
  width: 25%;
}

.testimonial_input {
  /* width: 200px; */
  margin: 0 auto;
  display: inline;
}

.test_input {
  width: 200px;
  height: 40px;
  font-size: 1.2rem;
  /* font-weight: bold; */
  background-color: #dddddd !important;
  margin: 5px;
  font-family: "Open Sans", sans-serif !important;
  margin-right: 15px;
  border: none !important;
  display: inline;
}
.test_input1 {
  width: 95%;
  height: 40px;
  font-size: 1.2rem;
  /* font-weight: bold; */
  background-color: #dddddd !important;
  margin: 5px;
  font-family: "Open Sans", sans-serif !important;
  margin-right: 15px;
  border: none !important;
}

.test_textarea1 {
  width: 95%;
  font-size: 1.2rem;
  /* font-weight: bold; */
  background-color: #dddddd !important;
  margin: 5px;
  font-family: "Open Sans", sans-serif !important;
  margin-right: 15px;
  border: none !important;
}

.test_file_input {
  margin-left: 5px;
  font-family: "Open Sans", sans-serif !important;
}

.test_msg {
  margin: auto !important;
  font-family: "Open Sans", sans-serif !important;
  font-size: 1.2rem;
  color: #4bb543;
  font-weight: 600;
  text-align: center;
}

.test_btn1 {
  width: 95%;
  height: 40px;
  font-size: 1.2rem;
  font-weight: bold;
  color: white;
  background-color: rgb(82, 239, 218);
  margin: 5px;
  font-family: "Spartan", sans-serif !important;
  margin-right: 15px;
  border: none !important;
  cursor: pointer;
}

.footer_about {
  padding-top: 20px;
 
  position: absolute;
  font-family: "Open Sans", sans-serif !important;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  z-index: 999;


  background-color: #050507!important;
}
.community {
  /* border: 1px solid red; */
  padding-top: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
 

}

.text {
  color: #cecccd;
  line-height: 24px;
  font-style: normal;
  size: 16px;
  font-family: "Open Sans", sans-serif !important;
  font-weight: 600;
  width: 200px;
  padding: 0%;
}

.whatsapp {
  color: white;
  font-family: "Open Sans", sans-serif !important;
  font-weight: 600;
  font-style: normal;
  line-height: 24px;
  font-size: 16px;
  border-radius: 10px;
  background-color: #13171e;
  /* backdrop-filter: blur(20px); */
  width: 200px;
  padding: 0.8rem;
  text-align: center;
  height: 100%;
  margin: 0;
  translate:transform 1s;
}
.whatsapp:hover{
  transform: scale(1.05);
  cursor: pointer;
}
.solid1 {
  /* margin-top: 1rem;
  margin-left: auto; */
  margin: auto;
  margin-top: 0.8rem;
  margin-left: 0.4rem;
  width: 97%;

    border: none;
    border-top: 2px solid rgba(44, 44, 44, 0.648);
   
  
}
.solid2{
      margin: auto;
      margin-top: 0.8rem;
      width: 86%;
      border: none;
      border-top: 2px solid rgba(44, 44, 44, 0.648);
}
.solid3{
  margin: auto;
  width: 86%;
  border: none;
  border-top: 2px solid rgba(44, 44, 44, 0.648);
}
.follow{
  display: flex;
   align-self: flex-end;
  justify-content: flex-end;
  margin: 1rem 5rem 1rem 1rem;
}
.follow_text{
  color: white;
 margin-right: 0.8rem;
}
a{
  text-decoration: none;
  color: #dddddd;
}

.hover-effect:hover {
  color: #ffffff;
  transform: scale(1.5);
}

/* Mobile view */

.community_mobile {
  padding-top: 0px;
  /* border: 1px solid red; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

}

.text_mobile {
  color: white;
  font-size: 1rem;
  font-family: "Open Sans", sans-serif !important;
  font-weight: 600;
  width: 280px;
  text-align: center;

}

.whatsapp_mobile {
  color: white;
  font-size: 1rem;
  font-family: "Open Sans", sans-serif !important;
  font-weight: 600;
  border-radius: 10px;
  background: linear-gradient(to top left, gray, #ffffff77);
  backdrop-filter: blur(20px);
  width: 280px;
  padding: 1rem;
  text-align: center;
  height: 100%;
  margin: 0;
}

.solid1_mobile {
  /* margin-top: 1rem;
  margin-left: auto; */
  margin: auto;
  margin-top: 1.3rem;
 
  width: 100%;

  border: none;
  border-top: 2px solid rgba(44, 44, 44, 0.648);

}

.solid2_mobile {
  margin: auto;
  margin-top: 0.8rem;
  width: 92%;
  border: none;
  border-top: 2px solid rgba(44, 44, 44, 0.648);
}

.follow_mobile{
    display: flex;
    align-self: center;
    margin-top:1.2rem;
    width: 100%;
    justify-content: center;
    margin-bottom: 5rem;
}

.follow_mobile .follow_icons_mobile{
      margin-right: 3rem;
      font-size: 2.5rem;
      color: gray;
}











.footer_grid {
  padding: 8px 15px!important;
  margin-top: 1rem!important;
  color: #dddddd;
  width: 20%;
}

.footer_head {
  font-family: "Spartan", sans-serif !important;
  margin-bottom: auto !important;
  margin-top: 5px !important;
  font-weight: 100 !important;
}

.footer_head1 {
  font-weight: 600 !important;
  padding-bottom: 0px !important;
  margin: auto ;
  margin-bottom: 12px;
  font-size: 20px;
}

.footer_para {
  font-family: "Open Sans",
    ui-sans-serif,
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    sans-serif,
    "Apple Color Emoji",
    "Segoe UI Emoji",
    "Segoe UI Symbol",
    "Noto Color Emoji";
  font-size: 16px !important;
  line-height: 24px;
  font-weight: 400;

}
.footer_para_privacy{
  font-family: "Open Sans",
      ui-sans-serif,
      system-ui,
      -apple-system,
      BlinkMacSystemFont,
      "Segoe UI",
      Roboto,
      "Helvetica Neue",
      Arial,
      "Noto Sans",
      sans-serif,
      "Apple Color Emoji",
      "Segoe UI Emoji",
      "Segoe UI Symbol",
      "Noto Color Emoji";
    font-size: 16px !important;
    line-height: 24px;
    font-weight: 400;
    border:1px solid red;
    width: 100px;
}

 .footer_grid .footer_link {
  text-decoration: none !important;
  font-family:"Open Sans",
    ui-sans-serif,
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    sans-serif,
    "Apple Color Emoji",
    "Segoe UI Emoji",
    "Segoe UI Symbol",
    "Noto Color Emoji"; ;
  font-size: 16px !important;
  color: #dddddd;
  font-weight: 400;
  transition: transform .2s;
}
.footer_link:hover{
  color: #6a58c6;
  /* transform: scale(15); */
  font-weight: 500;
}





@media only screen and (min-width: 720px) {
  .follow_mobile .follow_icons_mobile {
      margin-right: 5rem;
      font-size: 3.5rem;
    }

}

@media only screen and (max-width: 940px) {
  .know_your_score_welcome_box_left {
    display: none;
  }
  
}

@media only screen and (max-width: 420px) {
  .About_page {
    padding-bottom: 20% !important;
  }

  /* .Box_about {
    width: 94% !important;
  } */
  .next_prev_btn_container {
    display: block !important;
  }
  .know_your_score_about_heading {
    font-size: 1rem;
  }
  .know_your_score_para {
    font-size: 0.8rem !important;
  }
  .about_the_test_heading {
    font-size: 0.8rem;
  }
  .know_your_score_para_small {
    font-size: 0.7rem !important;
  }
  .questionaire_questions_heading {
    font-size: 1rem;
  }
  .ToggleButtonGroup {
    display: block !important;
    margin-top: 1%;
    margin-bottom: 1%;
  }
  .image_upload_btn {
    width: 50%;
  }
  .build_box {
    padding: 40px 0px !important;
    height: 85vh;
  }
  .about_heading {
    font-size: 1.8rem !important;
  }
  .about_para {
    font-size: 1rem !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .about_buttons {
    cursor: pointer !important;
    margin-left: 20% !important;
    margin-right: 20% !important;
    margin: 20px 0px;
  }
  .apply_button {
    margin: inherit !important;
  }
  .event_demo {
    padding: 30px 0px !important;
    display: flex;
    justify-content: center;
  }
  .partnership_demo {
    padding: 0px 10px !important;
  }
  .testimonials_demo {
    /* padding-left: 10px!important;
        padding-right: none!important;
        padding-top: 80px!important; */
    padding: 0px !important;
    padding-left: 4% !important;
    padding-right: 4% !important;
    /*background-color: yellow;*/
    height: 85vh;
  }
  .test_apply_btn {
    font-size: 0.8rem !important;
    height: 30px;
    padding: 0px 5px !important;
  }
  .test_input {
    width: 115px !important;
    margin: 3px !important;
  }
  .test_input1 {
    margin: 3px !important;
    width: 96% !important;
    font-size: 1rem;
  }
  .test_msg {
    font-size: 0.9rem !important;
  }
  .test_btn1 {
    margin: 3px !important;
    width: 98% !important;
  }
  .test_textarea1 {
    margin: 3px !important;
    width: 96% !important;
  }
  .test_file_input {
    margin: 3px !important;
  }
  .footer_body {
    margin-bottom: 2000px;
  }
  
  .footer_para {
    font-size: 16px !important;
    line-height: 24px;
    font-weight: 400;
    margin-right: 10px !important;
   
  }
  .footer_link {
    font-size: 0.9rem !important;
  }
  .member_demo {
    padding-top: 20px;
    /*background-color: yellow;*/
    height: 70vh;
  }
  .partnership_box {
    /*background-color: yellow;*/
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media only screen and (max-width: 360px) {
  .About_page {
    padding-bottom: 25% !important;
  }
}


@media only screen and (max-width:420px) {
  .follow_mobile .follow_icons_mobile {
      margin-right: 1.8rem;
      font-size: 2.1rem;
    }
}









