@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap');

.nav-item {
    position: relative;
  }
  
  .hover-box {
    position: absolute;
    top: 100%; /* Position it right below the menu item */
    left: 0;
    background-color: white;
    padding: 1rem;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    z-index: 1000; /* Ensure it's above other content */
  }
  
  .hover-content img {
    width: 100px; /* Adjust the size as needed */
    height: auto;
    margin-right: 1rem;
  }
  
  .hover-content p {
    margin-left: 1rem;
    display: flex;
    align-items: center;
  }
  .nav-item {
    position: relative; /* To position the dropdown relative to this item */
  }
  
  .dropdown-box {
    position: absolute;
    top: 100%; /* Position it right below the menu item */
    left: 0;
    background-color: white;
    padding: 1rem;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    z-index: 1000; /* Ensure it's above other content */
  }
  
  .dropdown-content {
    display: flex; /* Arrange items in a row */
    justify-content: space-between;
    width: 100%;
  }
  
  .dropdown-item {
    display: flex;
    align-items: center;
    margin-right: 1rem; /* Space between items */
  }
  
  .dropdown-item img {
    width: 50px; /* Adjust the image size */
    height: auto;
    margin-right: 0.5rem;
  }
  
  .dropdown-item p {
    margin: 0;
  }
.notificationBar{
    z-index: 9999;

}
.nav-item {
    position: relative;
  }
  
  .dropdown-menu {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #fff;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    list-style-type: none;
  }
  
  .dropdown-link {
    padding: 12px 16px;
    text-decoration: none;
    color: #000;
    display: block;
  }
  
  .dropdown-link:hover {
    background-color: #ddd;
  }
.dropdown-link:hover {
    text-decoration: underline;
    text-decoration-color: #FFBD59;
    text-decoration-thickness: 8;
  }
  
  .nav-menu .dropdown-menu.active {
    display: block;
  }
  
  @media (max-width: 1200px) {
    .dropdown-menu {
      position: static;
      background-color: transparent;
      box-shadow: none;
      width: '100%'
    }
    .dropdown-link{
        text-align: center;
    }
  }
  @media (max-width: 840px) {
    .dropdown-link{
        text-align: center;
    }
  }
  
  .dropdown-link:hover::after {
    width: 100%;
  }
.NavbarItems{
display: flex;
backdrop-filter: blur(15px);
justify-content: space-between;
align-items: center;
flex-direction: column;
padding: 0 30px;
box-shadow:0 5px 15px 0 rgba(0,0,0,0.25);
width:95%;
height:90px;
border-radius: 13px;
position:fixed;
top: 0px;
left: 50%;
transform: translate(-50%);
z-index: 9999;
font-family: 'Poppins', sans-serif;
}
.NavbarItems1{
    display: flex;
    backdrop-filter: blur(15px);
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
    width:95%;
    height:90px;
    border-radius: 13px;
    position:fixed;
    /* top: 0px; */
    left: 50%;
    transform: translate(-50%);
    z-index: 9999;
    font-family: 'Poppins', sans-serif;
    }

.navbarTopDropdown{
    visibility: hidden;
    display: flex;
    flex-direction: row;

}
.NavbarTopDropdown{
    display: flex;
    flex-direction: row;
}
.navbar-logo{
    color:#222;
    cursor:pointer;
    font-size:'24px';
}
.navbar-logo-2{
    color:#222;
    cursor:pointer;
    font-size:'30px';

}

.nav-menu{
    display: grid;
    grid-template-columns: repeat(7,auto);
    grid-gap:10px;
    list-style: none;
    align-items: center;

}

.nav-links{
    text-decoration: none;
    color: #222;
    font-size: 1.2rem;
    padding: 0.7rem 1rem;
    white-space: nowrap;
}

.nav-links:hover{
background-color: #6a58c6;
color:#fff;
border-radius: 4px;
transition: all 0.2s ease-in-out;
}

.nav-links-mobile-vsc{
    /* display: none; */
    padding: 1rem 1.5rem;

border: none;
border-radius: 0.3rem;
white-space: nowrap;
font-size: 1rem;
cursor: pointer;
font-weight: 600;
transition: 0.2s ease-in-out;
font-family:'Poppins';
color:'#fff';
background-color: #6A58C6;
    
}
.nav-links-mobile-vsc:hover{
    background-color: #6a58c6;
    color:#fff;
    transition: all 0.2s ease-in-out;
}

.button-vsc{
padding: 1rem 1.5rem;

border: none;
border-radius: 0.3rem;
white-space: nowrap;
font-size: 1rem;
cursor: pointer;
font-weight: 600;
transition: 0.2s ease-in-out;
}

.button-vsc:hover{
    background-color: #6a58c6;
    color:#fff;
    transition: all 0.2s ease-in-out;
}

.menu-icons{
    display: none;
}
@media screen and (max-width: 1200px){
    .navbar-logo{
        font-size:'17px';
    }
    .navbar-logo-2{
        font-size:'23px';
    
    }
} 
@media screen and (max-width: 900px){
    
   .navbarTopDropdown{
    visibility:visible;
   }
    .NavbarItems2 {
            position: fixed;
            /* background: linear-gradient(to top left, #6A58C6, #a8f5ff) !important; */
            backdrop-filter: blur(15px);
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 25px;
            box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.25);
            width: 95%;
            height: 80px;
            margin-bottom: 40rem;
            border-radius: 13px;
            position: fixed;
            top: 0px;
            left: 50%;
            transform: translate(-50%);
            z-index: 99;
            font-family: 'Poppins', sans-serif;
        }
    .NavbarItems {
     z-index: 99;
    }
       
    .nav-menu{
        display: flex;
        flex-direction: column;
        backdrop-filter: blur(15px);
        justify-content: flex-start;
        width: 100%;
        background: #fff;
        height: auto;
        position: absolute;
        top: 0;
        left: -100%;
        opacity: 1;
        align-items: stretch;
        padding: 80px 0 30px 0;
        transition: 0.3s ease-in-out;
        border-radius: 13px;
        margin: 0;
    }

    .nav-menu.active{
        left: 0;
        backdrop-filter: blur(15px);
        opacity: 1;
        z-index: -1;
        transition: 0.3s ease-in-out;
    }
       
  
.nav-links{
    display:block;
    width:100%;
    text-align: center;
    font-size: 1.3rem;
    padding:2rem 0;
}

.nav-links:hover{
    background: #6a58c6;
    transition: none;
}

.button-vsc{
    display: none;
}
 .NavbarTopDropdown {
     visibility: hidden;
  
 }

.nav-links-mobile-vsc{
    display: block;
    text-align: center;
    padding: 1.5rem;
    margin: auto;
    border-radius: 0.6rem;
    width: 80%;
    background-color: #6A58C6;
    text-decoration: none;
    color:#fff;
    font-size: 1.2rem;
    font-weight: 700;
    letter-spacing: 2px;
}
.menu-icons {
    display: block;
    cursor: pointer;
}
}