.Signup_Form_container {
       margin-top: 5rem;
        font-family: 'Open Sans', sans-serif !important;
        padding-top: 6%;
        padding-left: 5%;
        padding-right: 5%;
        padding-bottom: -10rem;
        /* background: linear-gradient(to top left, #6A58C6, #a8f5ff) !important; */
        overflow-y: hidden;
        /* border:1px solid red; */
        height: 85vh;
}

.left_box_type {
  width: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 10px;
  margin-top: 1rem;
  font-size: 4rem;
  padding: 3rem;
  color: white;
  font-family: "Spartan", sans-serif !important;
}
.titleSignup{
  animation-name:moveRight;
  animation-duration: 0.7s;
  color: #000000;
}

.titleTwoSignup{
  animation-name: moveLeft;
  animation-duration: 1s;
color: #000000;
}

.titleThreeSignup{
  animation-name: moveRight;
  animation-duration: 1.3s;
color: #000000;
  margin-bottom: 10px;
}

.right_box_type {
  width: 100%;
} 

.signup_form {
  background: linear-gradient(to top left, #ffffffff,#ffffff33);
  backdrop-filter: blur(15px);
  border-radius: 10px;
  width: 60%;
  margin: auto;
  margin-top: 5%;
  box-shadow: 2px 2px 5px #2773a5 ;
  z-index: 2;
  padding: 3%;
}

.signup_form > h3 {
  text-align: center;
}

.auth_button2{
  cursor: pointer !important;
  width: 100%;
}
.auth_button2 .button_signup{
  background-color: #6A58C6;
  color: white;
  width: 100%;
  padding: 1rem;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 4px;  
  border: none;
  cursor: pointer;
  margin-top: 0rem;
  
}
.auth_button2 .button_signup:hover{
background-color: #2773a5;
}


.error {
  color: red;
}

.Box {
  margin-top: 4rem;
}


.textSignup{
  font-size:40px;
  color: #6A58C6;
  font-weight: 600;
  margin-bottom: 20px;
}

@keyframes moveRight{
  0%{
    opacity:0;
    transform:translateX(100px);
  }

  100%{
    opacity:1;
    transform:translate((0));
  }
}
@keyframes moveLeft{
  0%{
    opacity:0;
    transform:translateX(-150px);
  }

  100%{
    opacity:1;
    transform:translate((0));
  }
}
@keyframes moveUp{
  0%{
    opacity:0;
    transform:translateY(50px);
  }

  100%{
    opacity:1;
    transform:translate((0));
  }
}

.number_of_users {
  margin: auto;
  font-family: "Spartan", sans-serif !important;
}
.Alert_type {
  width: 90% !important;
  margin: auto !important;
}




/* media queries */

.Alert_type {
  margin-bottom: 0.5rem !important;
}

@media only screen and (min-width: 420px) {
  .Alert_type {
    width: 26rem !important;
  }
}


@media only screen and (max-width: 1280px) {
  .Signup_Form_container {
    padding-bottom: 20%;
    height: 100%;
  } 
  .signup_form {
    width: 90%;
  }
  .textSignup{
    display: flex;
    width: 90vw;
    font-size: 2rem;
    margin-left: -40px; 
  }
  .main-heading-signup{
    font-size: 3rem;
    margin-left: -40px ;;
  }
  .left_box_type{
     padding-top: 1rem !important;
  }
}
@media screen and (max-width:700px){
  .Signup_Form_container {
    padding-bottom: 20%;
    height: 100%;
  }
  .signup_form {
    width: 90%;
  }
  .textSignup{
    display: flex;
    width: 90vw;
    /* font-size: 1.8rem; */
    margin-left: -40px ;
  }
  .main-heading-signup{
  font-size: 3rem;
    margin-left: -45px;
  }
  .left_box_type{
     padding-top: 1rem !important;
  }
}
@media only screen and (max-width: 420px) {
  .Signup_Form_container {
    padding-bottom: 25%;
    height: 100%;
  }
  .signup_form {
    width: 90%;

  }
  .textSignup{
    display: flex;
      width: 90vw;
      margin-left: -40px !important;
      font-size: 1.2rem;
  }
  .main-heading-signup{
    font-size: 2.5rem;
  }
}

@media only screen and (max-width: 320px) {
  .Signup_Form_container {
    padding-bottom: 30%;
  }
  .signup_form {
    width: 90%;
  }
  .main-heading-signup{
    font-size: 1.5rem;

  }
  .left_box_type{
    margin-left: -1rem !important;
    margin-bottom: auto;

 }
}


/* The code is very responsive and I did not write the confusing classnames */