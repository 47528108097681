.dashboard_navbar{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}
.cards{
    display: flex;
    justify-content: space-evenly;
    margin-top: 25px;
}

.card{
    height: 350px;
    width: 25%;
    border-radius: 25px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); 
    color: black;
    cursor: pointer;
}

.card:hover{
    background-color: #6A58C6;
    transform: scale(1.05);
    transition: transform 0.3s ease-in-out;
}