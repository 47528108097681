@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600&family=Poppins:wght@600;700&display=swap');

.course_landing{
    /* border:1px solid red; */
    margin-top: 6rem;
    /* margin:auto; */
    /* padding: 0rem 0.5rem; */
}

#our-courses-course{
    /* border:1px solid red; */
    font-size: 2.5rem;
    margin-left: -1.5rem;
}
.details_main {
    background-color: white;
    /* width: 100%; */
    overflow: hidden;
    padding: 0 22px;
    position: relative;
}

.heading {
    padding-top: 120px;
    /* border: 1px solid red; */
  
}

.heading h1 {
    height: auto;
    color: #0A0103;
    font-size: 48px;
    font-family: "Poppins", sans-serif;
    margin: 0px 0px 24px;
    text-align: center;
    margin-left: 22rem;
    /* border: 1px solid red; */

}

.heading p {
    color: #6c6768;
    
    font-size: 24px;
    font-family: "Open Sans", sans-serif;
    text-align: center;
    margin-left: 20rem;
    word-wrap: break-word;
    box-sizing: border-box;
}

.video_box {
    /* border: 1px solid red; */
    height: 500px;
    width: auto;
    margin-top: 2rem;
    margin-bottom: 3rem;
    /* padding: 0px 16px; */
    
    display: flex;
    /* justify-content: space-around; */
    /* align-items: center; */
}
#widget2{
        border-radius: 40px;
        width:100%;
        height:100%;
        margin-left: 22rem;
       
        /* border: 1px solid red; */
}
.video_box .video {
    border-radius: 40px;
    /* border: 1px solid red; */
    width: 70%;
    height: 97%;
}

.container_data {
    height: 158px;
    /* border: 1px solid red; */
    width: 100vw;
    /* margin-inline: auto */
  
    margin-left: -22px;
    display: flex;
    margin-bottom: 2rem;
    color: #1a202c;
    justify-content: center;
    background-color: #f7f7ff;
}

.container_data .first_data {
    padding: 32px 0px;
    width: 192px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 7%;
    /* border: 1px solid red; */
}

.container_data .first_data p {
    font-size: 18px;
    font-family: "poppins";
    margin: 8px 0px 0px;
    height: 54px;
    color: #0A0103;
    text-align: center;
    font-weight: 700;
}

.container_data .second_data {
    padding: 32px 0px;
    width: 192px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 7%;
    /* border: 1px solid red; */
}

.container_data .second_data p {
    font-size: 18px;
    font-family: "poppins";
    margin: 8px 0px 0px;
    height: 54px;
    color: #0A0103;
    text-align: center;
    font-weight: 700;
}

.container_data .third_data {
    padding: 32px 0px;
    width: 192px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* margin-right: 7%; */
    /* border: 1px solid red; */
}

.container_data .third_data p {
    font-size: 18px;
    font-family: "poppins";
    margin: 8px 0px 0px;
    height: 54px;
    color: #0A0103;
    text-align: center;
    font-weight: 700;
}

.box_of_data{
  margin: 80px 0px 0px;
   display: flex;
  width: 95%;
 
  padding: 0px 2rem 0px 0;
   /* border: 1px solid red; */
}
.sidebar2{
    height: 304px;
    height: fit-content;
    width: 280px;
    padding: 40px;
     display: block;
}

.sidebar {
    /* height: 304px; */
    position: fixed;
    height: fit-content;
    width: 15%;
    padding: 40px;
   /* margin-top: 6rem; */

   top: 15%;
    /* border: 1px solid red; */
    /* top: 0; */
    left: 10;
    z-index: 9999;
   
    /* border-color: #E2E8F0; */
    
    box-shadow: rgba(0, 0, 0, 0.1) 0px -2px 11px -2px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    border-radius: 1.5rem;

}

.sidebar .sidebar_heading {
    color: #21191B;
    font-size: 16px;
    font-family: Poppins,
        ui-sans-serif,
        system-ui,
        -apple-system,
        BlinkMacSystemFont,
        Segoe UI,
        Roboto,
        Helvetica Neue,
        Arial,
        Noto Sans,
        sans-serif,
        Apple Color Emoji,
        Segoe UI Emoji,
        Segoe UI Symbol,
        Noto Color Emoji;
    padding: 20px 0px;
    font-weight: 700;
    text-align: start;
    border-bottom: 2px solid rgba(225, 225, 225, 0.962);

}

.sidebar .sidebar_data {
    color: #0A0103;
    font-size: 16px;
    font-family: "Open Sans", sans-serif;
    margin: 32px 0px 0px;
    font-weight: 600;
}
.sidebar .sidebar_data:hover{
    color: #6a58c6;
    cursor: pointer;
}
.sidebar .sidebar_data:active{
    color: #6a58c6;
    cursor: pointer;
}

.sidebar .apply-now button {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    width: 100%;
    margin: 2.5rem 0 0;
    color: #FFFFFF;
    font-size: 14px;
    font-family: "Open Sans", sans-serif;
    border-radius: 8px;
    background-color: #6a58c6;
    border: none;
    text-transform: uppercase;
    cursor: pointer;
}

.sidebar .apply-now .details {
    padding-top: 0.75rem;
        padding-bottom: 0.75rem;
    font-size: 14px;
    font-family: "Open sans", sans-serif;
    background-color: #FFFFFF;
    color:#6a58c6;
    font-weight: 600;
    border-radius: 8px;
    border: 1px solid #6a58c6;
    margin: 2.5rem 0 0;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 0.7px;
}
.sidebar .apply-now .details:hover {
    cursor: pointer;
    background-color: rgba(254, 242, 242, 1);
}
.left_box_of_data{
       width: 90%; 
        margin-left: 2rem;
        /* border: 1px solid red; */
       
}
.intro {
    /* height: 121px; */
    /* width: 95%; */
    /* border: 1px solid red; */
    background: #6E71CC;
    border-radius: 0.75rem;
    display: flex;
    align-items: center;
    padding: 48px 40px;
    justify-content: center;
}
.intro2 {
    /* height: 121px; */
    /* width: 95%; */
    /* border: 1px solid red; */
    background: #ffffff;
    border-radius: 0.75rem;
    display: flex;
    align-items: center;
    padding: 48px 40px;
    justify-content: center;
    display: none;
}

.intro .intro-data {
    /* border: 1px solid red; */
    width: 100%;
    height: 41px;
    display: flex;
    justify-content: space-between;
}
.intro2 .intro-data {
    /* border: 1px solid red; */
    width: 100%;
    /* height: 41px; */
    display: flex;
    justify-content: space-between;
}
.intro .intro-data .intro-heading{
        display: flex;
        /* border: 1px solid red; */
        width: 70%;
}
.intro2 .intro-data .intro-heading {
    display: flex;
    /* border: 1px solid red; */
    width: 100%;
    padding-bottom: -1rem;
}

.intro .intro-data .intro-heading .intro-button-1{
           background: #8689D4;
            width: 100%;
            margin: 0 12px 0 0;
            padding: 8px 16px;
            text-align: center;
            color: #FFFFFF;
            font-size: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: Open Sans, sans-serif;
}
.intro2 .intro-data .intro-heading .intro-button-1 {
    background: #8689D4;
    width: 100%;
    margin: 0 12px 0 0;
    padding: 8px 16px;
    text-align: center;
    color: #FFFFFF;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 1px solid red; */
    font-family: Open Sans, sans-serif;
}
.intro .intro-data .intro-heading .intro-button-2 {
    background: #8689D4;
    width: 100%;
    margin: 0 12px 0 0;
    padding: 8px 16px;
    text-align: center;
    color: #FFFFFF;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Open Sans, sans-serif;
}
.intro2 .intro-data .intro-heading .intro-button-2 {
background: #8689D4;
    width: 100%;
    margin: 0 12px 12px 0;
    padding: 8px 16px;
    text-align: center;
    color: #FFFFFF;
    font-size: 16px;
    display: flex;
    align-items: center;
    /* border:1px solid red; */
    justify-content: center;
    font-family: Open Sans, sans-serif;
}
.part_time_course{
    margin: -2rem -2rem 0 3rem;
            padding: 8px 16px 0px 16px;
            color: #FFFFFF;
            height: 100px;
    
            font-size: 16px;
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            text-transform: uppercase;
            font-family: Open Sans, sans-serif;
             /* border: 1px solid red; */
}
#our-courses{
    font-size: 1.8rem;
    margin-left: -1.5rem;
}
#course-details {
    /* height: 442px; */
    /* width: 100%; */
    margin: 30px 0px 0px;
    padding: 32px 24px;
    border: 2px solid #F3F2F2;
    border-radius: 0.5rem;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
            rgba(17, 17, 26, 0.1) 0px 0px 8px;
    /* border: 1px solid red; */
}

#course-details h2 {
    color: #0A0103;
    font-family: "Poppins";
    font-size: 25px;
    font-weight: 700;
    /* border: 1px solid red; */
}

.details_boxs {
    /* border: 1px solid red; */
    padding: 48px 0px;
    height: auto;

}

.box_container {
    padding: 0px 1rem;
    display: flex;
    /* border: 1px solid red; */
    align-items: center;
    justify-content: space-between;
    max-width: 80rem;
}

.details_boxs .box1 {
    display: flex;
    flex-direction: column;
    padding: 2.5rem 0 2.5rem 0;
    font-size: 1.125;
    /* height: 142px; */
    width: 30%;
    background-color: #F7F7FF;
    text-align: center;
    /* width: auto; */
    border-radius: 16px;
    align-items: center;
    /* border: 1px solid red; */

}

.elligibilty {
    /* border: 1px solid red; */
    width:auto;
   
    border: 2px solid #F3F2F2;
    border-radius: 0.5rem;
    padding: 32px 24px;
    margin: 30px 0px 0px;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
            rgba(17, 17, 26, 0.1) 0px 0px 8px;
}
.elligibilty_box {
    /* border: 1px solid red; */
    margin-top: 2rem;
    max-width: 80rem;
}

.elligibilty h2 {
    color: #0A0103;
    font-family: "Poppins";
    font-size: 25px;
    font-weight: 700;
  
    /* border: 1px solid red; */
}
#box1-p{
    width: 24rem;

    /* border: 1px solid red; */
}
.faq {
    width: auto;
    border: 2px solid #F3F2F2;
    border-radius: 0.5rem;
    padding: 32px 24px;
    margin: 30px 0px 0px;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
            rgba(17, 17, 26, 0.1) 0px 0px 8px;
}

.faq h2 {
    color: #0A0103;
    font-family: "Poppins";
    font-size: 25px;
    font-weight: 700;

    /* border: 1px solid red; */
}


/* **************************** */

.kickstart_courses_box {
    /* border: 1px solid red; */
    /* height: 100%; */
    margin:20px 0px 0px;
    padding: 24px 24px;

}

.kickstart_courses_box h2 {
        color: #0A0103;
        font-family: "Poppins";
        font-size: 28px;
        font-weight: 700;
   
    /* border: 1px solid red; */
}

.level_courses {
    display: flex;
    margin-right: 10px;
    margin-left: 10px;
    margin-top: 3rem;
    width:100%;
    justify-content: space-evenly;
    align-items:center;
    /* border: 1px solid red; */
}

.course_1 {
    box-shadow: 0 10px 10px 0 #0000000d;
    width: 27%;
    border: 1px solid rgb(229, 228, 228);
    border-radius: 18px;
}

.course_2 {
    box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.05);
    width: 33%;
    border: 1px solid rgb(229, 228, 228);
    border-radius: 18px;
}

.course_3 {
    box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.05);
    width: 33%;
    border: 1px solid rgb(229, 228, 228);
    border-radius: 18px;
}

.course_1 .course_data {
    width: 93%;
    /* border: 1px solid red; */
    /* min-height: 15rem; */
    margin: 10px;
}


.course_2 .course_data {
        width: 93%;
        /* border: 1px solid red; */
        min-height: 15rem;
        word-wrap: break-word;
        margin: 10px;
}

.course_3 .course_data {
width: 93%;
    /* border: 1px solid red; */
    min-height: 15rem;
    margin: 10px;
}

.course_1 .course_1_button {
    /* border: 1px solid red; */
    margin: 35px 0px 0px 0px;
    display: flex;
    justify-content: flex-end;
    padding: 8px 10px;
}

.course_2 .course_2_button {
    /* border: 1px solid red; */
        margin: 35px 0px 0px 0px;
        display: flex;
        justify-content: flex-end;
        padding: 8px 10px;
}

.course_3 .course_3_button {
        /* border: 1px solid red; */
            margin: 35px 0px 0px 0px;
            display: flex;
            justify-content: flex-end;
            padding: 8px 10px;
}







.course_1 .course_1_button button {
    padding: 12px 16px;
    font-size: 14px;
    font-family: "Open sans", sans-serif;
    background-color: #6a58c6;
    color: #FFFFFF;
    border-radius: 8px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 0.7px;
}

.course_1 .course_1_button button:hover {
    cursor: pointer;
    background-color: #6a58c6;
}

.course_1 .course_1_button .details {
    padding: 12px 16px;
    font-size: 14px;
    font-family: "Open sans", sans-serif;
    background-color: #FFFFFF;
    color: #6a58c6;
    font-weight: 600;
    border-radius: 8px;
    border: 1px solid #6a58c6;
    margin-right: 0.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 0.7px;
}

.course_1 .course_1_button .details:hover {
    cursor: pointer;
    background-color: rgba(254, 242, 242, 1);
}

.course_2 .course_2_button button {
    padding: 12px 16px;
    font-size: 14px;
    font-family: "Open sans", sans-serif;
    background-color: #6a58c6;
    color: #FFFFFF;
    border-radius: 8px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 0.7px;
}

.course_2 .course_2_button button:hover {
    cursor: pointer;
    background-color: #6a58c6;
}

.course_2 .course_2_button .details {
    padding: 12px 16px;
    font-size: 14px;
    font-family: "Open sans", sans-serif;
    background-color: #FFFFFF;
    color: #6a58c6;
    font-weight: 600;
    border-radius: 8px;
    border: 1px solid #6a58c6;
    margin-right: 0.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 0.7px;
}

.course_2 .course_2_button .details:hover {
    cursor: pointer;
    background-color: rgba(254, 242, 242, 1);
}


.course_3 .course_3_button button {
    padding: 12px 16px;
    font-size: 14px;
    font-family: "Open sans", sans-serif;
    background-color: #6a58c6;
    color: #FFFFFF;
    border-radius: 8px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 0.7px;
}

.course_3 .course_3_button button:hover {
    cursor: pointer;
    background-color: #6a58c6;
}

.course_3 .course_3_button .details {
    padding: 12px 16px;
    font-size: 14px;
    font-family: "Open sans", sans-serif;
    background-color: #FFFFFF;
    color: #6a58c6;
    font-weight: 600;
    border-radius: 8px;
    border: 1px solid #6a58c6;
    margin-right: 0.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 0.7px;
}

.course_3 .course_3_button .details:hover {
    cursor: pointer;
    background-color: rgba(254, 242, 242, 1);
}
@media screen and (max-width:1660px){
    .heading h1{
        margin-left: 20rem;
    }
    .heading p{
        font-size: 20px;
    }
}
@media screen and (max-width:1448px) {
    .left_box_of_data{
        width: 75%; 
        
        /* border: 1px solid red; */
       
    }
  
  
}
@media screen and (max-width:1100px) {
    .level_courses {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        /* border: 1px solid red; */
    }

    .course_1 {
        width: 50%;
    }

    .course_2 {
        width: 50%;
        margin-top: 2rem;
    }

    .course_3 {
        width: 50%;
        margin-top: 2rem;
    }
    .sidebar{
        width: 19%;
    }
}
@media screen and (max-width:700px){
    .course_1 {
        width: 100%;
    }

    .course_2 {
        width: 100%;
    }

    .course_3 {
        width: 100%;
    }
}
@media screen and (max-width:1300px) {
    .box_container{
        /* border: 1px solid black; */
        flex-direction: column;
    }
    .details_boxs .box1{
          width: 100%;
          margin-left: 1.8rem;
          margin-bottom: 1rem;
    }
        .intro .intro-data .intro-heading{
            flex-direction: column;
            height: 100px;
            margin-top: -2rem;
            margin-left:-1rem;
        }
                  .intro2 .intro-data .intro-heading {
                      flex-direction: column;
                      height: 100px;
                      margin-top: -2rem;
                      margin-left: -1rem;
                  }
       .intro .intro-data .intro-heading .intro-button-1{
        width: 87%;
        margin-bottom: 1rem;
        }
                 .intro2 .intro-data .intro-heading .intro-button-1 {
                     width: 87%;
                     margin-bottom: 1rem;
                 }

        .intro .intro-data .intro-heading .intro-button-2{
              width: 87%;
              
          }
                        .intro2 .intro-data .intro-heading .intro-button-2 {
                            width: 87%;
            
                        }
                 .elligibilty_box .box1 {
                      flex-direction: column;
        
                  }
        
                 .elligibilty_box .box1 .box1_right {
                      margin-left: 0;
        
                      margin-top: -1rem;
                  }
        
                 .elligibilty_box .box2 {
                      flex-direction: column;
                  }
        
                .elligibilty_box  .box2 .box2_right {
                      margin-left: 0;
                    margin-top: -1rem;
                  }
        
                 .elligibilty_box .box3 {
                      flex-direction: column;
                      /* border: 1px solid red; */
                  }
        
                 .elligibilty_box .box3 .box3_right {
                      margin-left: 0;
                    margin-top: -1rem;
                  }

                 #widget2 {
                      width: 100%;
                  }
}
@media screen and (max-width:1048px){
    #widget2{
        margin-left: 18rem;
    }
}
@media screen and (max-width:850px){
    .intro{
        display: none;
    }
    .intro2{
        display: flex;
    }
    .heading h1{
        margin-left: 0;
        /* border:1px solid red; */
       padding-top: 3rem;
    }
    .heading p{
        margin-left: 0;
        font-size: 24px;
    }
    #widget2{
        margin-left: 0;
    }
    .sidebar{
        display: none;
    }
    .sidebar2{
        display: none;
    }

    .box_of_data{
        width:auto ;
        margin: 0;
        padding: 0px;
    }
     
    .left_box_of_data {
     /* border: 1px solid red; */
    width: 100%;
    margin:0;
   }

   .heading {
       padding-top: 0px;
       /* border: 1px solid red; */
   }
   .heading h1 {
       height:auto;
       
       font-size: 40px;
     
       margin-top: 4rem;
     
       /* border: 1px solid red; */

   }


  /*
        .intro{
            
            margin-left: -30px;
            margin-right: 50px;
        } */
        
      
}

@media screen and (max-width:562px){
.part_time_course {
    display: none;
}
#box1-p{
    width: 100%;
}
  .intro .intro-data .intro-heading {
      /* flex-direction: row; */
      height: 100px;
       width:150%;
      /* border: 1px solid red; */
      margin-top: -1.8rem;
      margin-left: -1rem;
      margin-right: 1.2rem;
      padding: 0;
  }
   .intro2 .intro-data .intro-heading {
       /* flex-direction: row; */
       height: 100px;
       width: 150%;
       /* border: 1px solid red; */
       margin-top: -1.8rem;
       margin-left: -1rem;
       margin-right: 1.2rem;
       padding: 0;
   }

  .intro .intro-data .intro-heading .intro-button-1 {
      width: 100%;
      margin-bottom: 1rem;
      height: 50%;
      margin-right: 20px;
  }
        .intro2 .intro-data .intro-heading .intro-button-1 {
            width: 100%;
            margin-bottom: 1rem;
            height: 50%;
            margin-right: 20px;
        }

  .intro .intro-data .intro-heading .intro-button-2 {
     width: 100%;
     height: 50%;
  }
  .intro2 .intro-data .intro-heading .intro-button-2 {
      width: 100%;
      height: 50%;
  }

  .container_data {
      height: auto;
      /* border: 1px solid red; */
      width: 100vw;
      /* margin-inline: auto */

      margin-left: -22px;
      display: flex;
      flex-direction: column;
      margin-bottom: 2rem;
      color: #1a202c;
      justify-content: center;
      background-color: #f7f7ff;
  }
  .container_data .first_data {
      width: auto;
      flex-direction: column;
      margin-right: 0%;
      /* border: 1px solid red; */
  }
  .container_data .second_data {
        width: auto;
        flex-direction: column;
        margin-right: 0%;
        /* border: 1px solid red; */
  }
   .container_data .third_data {
        width: auto;
        flex-direction: column;
        /* border: 1px solid red; */
      }
}

@media screen and (max-width:350px){
    .heading h1 {
            height: auto;
    
            font-size: 30px;
    
            margin-top: 2rem;
    
            /* border: 1px solid red; */
    
        }
.heading p {
    font-size: 20px;
    /* border: 1px solid red; */
}
}
