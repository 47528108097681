.Maincontainer2{
    margin-top: 120px;
    font-family: "Poppins", sans-serif;
}
.team-registration-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }

  
  .team-registration-form h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-group label {
    display: block;
    font-weight: bold;
  }
  
  .form-group input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .form-group textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .payment-details {
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 20px;
  }
  
  .payment-details p {
    margin: 0;
  }
  .registrationButton{
    background-color: #6a58c6;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    width: 10%;
    margin-bottom: 10px;
  }
  .button-payment {
    background-color: #6a58c6;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    width: 100%;
  }
  .confirmation-summary{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .confirmation-summary-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .confirmation-summary-content-2{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  .confirmation-summary-buttons{
    margin: 10px;
  }
  .confirmation-summary-button{
    margin: 10px;
    background-color: #6a58c6;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    width: 100%;
  }
  .button-payment:hover {
    background-color: #7d69d9;
  }
  @media (max-width : 768px){
    .registrationButton{
      background-color: #6a58c6;
      color: #fff;
      padding: 10px 20px;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      font-size: 16px;
      font-weight: bold;
      width: 70%;
      margin-bottom: 10px;
    }
  }