@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600&family=Poppins:wght@600;700&display=swap');

.startup-container{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .startup-cases{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    /* padding: 2; */
  }
  /* .startup-out{
    display: flex;
    /* align-items: center; */
    /* flex-direction: column; */
    /* justify-content: center; */
    /* background-color: #f6f6f6; */
    /* padding:'1%'; */
    /* border-radius: 25px; */
    /* height: 100%;
    width: 100%; */
    /*box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
  } */
.control-arrow {
    background: #6a58c6;
    color: #fff;
    font-size: 20px;
    border: none;
    cursor: pointer;
    margin-left: 20%;
    margin-right: 20%;
  }

  
  
.v_main{
    background-color: white;
    width: 100%;
    overflow: hidden;
}

a{
    text-decoration: none;
}

/**************appyandheading*************/

.appyandheading {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 450px;
    margin-top: 40px;
    padding: 30px 0px;
    /* border: 1px solid red */
}

.textInside {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* border: 1px solid red; */
    /* margin-top: -2rem; */
    width: 100%;
    color:'#000';

}

.appyandheading h1 {
    font-size: 45px;
    width: 75%;
    /* border:1px solid red; */
    /* height: 124px; */
    padding: 20px;
    font-weight: 700;
    /* line-height: 62px; */
    font-family: "Poppins", sans-serif; 
    text-align: center;
    color: #1a202c;
    /* word-wrap: break-word; */

}
.carousel .slider-wrapper.axis-horizontal .slider{
    display: center;
    align-items: center;

}
.appyandheading h2{
    font-size: 40px;
        width: 75%;
        /* border: 1px solid red; */
        padding: 20px;
        font-weight: 700;
        margin-top: -2rem;
        font-family: "Poppins", sans-serif;
        text-align: center;
        color: #1a202c;
        /* word-wrap: break-word; */
}
 
.appyandheading .p1 {
    width: 90%;
    height: 50px;
    font-size: 1.3rem;
    margin-top: 1rem;
    word-wrap: break-word;
    color:'#000';
}
.appyandheading .p2{
    font-weight: bold;
    font-size: 1.7rem;
    text-align: center;
    margin-top: -20px;
    margin-bottom: 6px;
    color:'#000';

}
.appyandheading .pNew{
    width: 90%;
    height: 50px;
    font-size: 1.7rem;
    margin-top: 0;
    word-wrap: break-word;
    color:'#000';
    margin-bottom: 10px;
}

.appyandheading img {
    position: relative;
    top: -21px;
}

 .ApplyNowButton {
     width: 200px;
     height: 45px;
     background-color: #6a58c6;
     color: white;
     display: flex;
     justify-content: center;
     align-items: center;
     border-radius: 5px;
     margin: 0px 15px ;
     /********/
 }

 .ApplyNowButton a {
     color: white;
     text-decoration: none;
 }



/*********image section stude*******/
 .StudentImage1 {
     height: 300px;
     width: 100%;
     background-image: url("../components/NewVirtualCampusStartup/Images/company_image.jpg");
    /* background-image: url("../components/NewVirtualCampusStartup/Images/poster.png"); */
     background-repeat: no-repeat;
     background-size: cover;

 }

 .StudentImage2 {
     height: 300px;
     width: 100%;
     background-image: url("../components/NewVirtualCampusStartup/Images/company_image.jpg");
    /* background-image: url("../components/NewVirtualCampusStartup/Images/poster.png"); */
     background-repeat: no-repeat;
     background-size: cover;


 }

 .StudentImage3 {
     height: 300px;
     width: 100%;
     background-image: url("../components/NewVirtualCampusStartup/Images/company_image.jpg");
    /* background-image: url("../components/NewVirtualCampusStartup/Images/poster.png"); */
     background-repeat: no-repeat;
     background-size: cover;

 }
 
 .PictureSection {
     width: auto;
     height: 350px;
     overflow: hidden;
    margin-top: 2rem;
 }
.pictureMarquee {
    width: 4000px;
    height: 100%;
    display: flex;
    flex-direction: row;
    animation-name: marqueeAnimation;
    animation-duration: 60s;
    animation-iteration-count: infinite;
    animation-play-state: running;
    animation-direction: reverse;
    animation-delay: 0ms;
    animation-timing-function: linear;

}

.pictureMarquee:hover {
    animation-play-state: paused;
}

@keyframes marqueeAnimation {
    0% {
        transform: translateX(-1300px);
    }


}


/***************packageRalatiedNews*********************/
.packageRalatiedNews {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    margin: 0px 0px 70px 0px;
    /* border: 1px solid red; */

}

.packageDetailsAndEnrollment {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    /* border: 1px solid red; */
  
}

.Enrolled {
    width: 616px;
    height: 280px;
    background-color: #251E4F;
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "poppins", sans-serif;
    flex-direction: column;
    font-size: 34px;
    line-height: 40px;
    text-align: center;
    border-radius: 30px;
    font-style: normal;
    color: #ffffff;
    font-weight: 700;
    margin: 10px;
}

.Enrolled span span {
    color: #ffdb66;
    font-weight: 700;
}

.lowestLPA {
display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 292px;
    height: 280px; 
    padding: 10px;
    background-color: #6FBDCD;
    border-radius: 30px;
    margin: 10px;
    font-size: 33px;
    text-align: center;
    font-family: "poppins", sans-serif;
    font-style: normal;
    color: #ffffff;
    line-height: 40px;
    font-weight: 700;
}

.heightLPA {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 300px;
    height: 280px;
    padding: 10px;
    background-color: #6A58C6;
    border-radius: 30px;
    margin: 10px;
    font-size: 33px;
    text-align: center;
    font-family: "poppins", sans-serif;
    font-style: normal;
    color: #ffffff;
    line-height: 40px;
    font-weight: 700;
}


/********MasaiInTheNews************/
.MasaiInTheNews {
    height: 620px;
    width: 604px;
    background-color: rgb(133, 108, 223);
    margin: 10px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.MasaiInTheNews img {
    width: 576px;
    height: 318px;
}

.MasaiInTheNews_text {
    font-size: 45px;
    font-weight: 900;
    color: white;
    margin: 60px 0px 0px 0px;
}

.MasaiInTheNews button {
    border: none;
    border: 1px solid white;
    background-color: transparent;
    font-size: 15px;
    font-weight: bold;
    padding: 10px;
    margin: 30px 0px;
    color: white;
}


.courses-main{
    width: 100%;
     height: 790px;
     /* border: 1px solid red; */
     display: flex;
     flex-direction: column;
     align-items: center;
}

.main-heading_virtual{
    /* border: 1px solid red; */
    width: 90%;
    height: 18%;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.textVSC{
    color:#010A03;
    font-size: 48px;
    font-weight: 700;
    line-height: 40px;
    font-family:"Poppins",
        ui-sans-serif,
        system-ui,
        -apple-system,
        BlinkMacSystemFont,
        "Segoe UI",
        Roboto,
        "Helvetica Neue",
        Arial,
        "Noto Sans",
        sans-serif,
        "Apple Color Emoji",
        "Segoe UI Emoji",
        "Segoe UI Symbol",
        "Noto Color Emoji";
        color: #6A58C6;
        margin: 18px 0px 24px 0px ;
        text-align: center;

}
.main-heading_virtual p {
    color: #3b3435;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    font-family: "Open Sans";
    margin: 5px 0px 0px;
 text-align: center;
}

.v_courses{
    /* border:1px solid red; */
   
    width: 1370px;
    display: flex;
    /* border: 1px solid red; */
}

.v_courses .box_1{
    /* border: 1px solid red; */
    width: 50%;
   
    display: flex;
    justify-content: center;
    
}

.v_courses .box_1 .course_box_1{
      width: 70%;
  
      margin: 5%;
      margin-right: -50px;
      border-radius: 0.5rem;
      border: 1px solid #E2E8F0;
}

.v_courses .box_1 .course_box_1 .course_box_1_image{
          width: 192px;
            height: 192px;
            border-radius: 50%;
            position: relative;
            top: -60px;
            left: 27.5%;
            background-color: white;
            padding: 4px;
            /* border: 1px solid red; */
}

.v_courses .box_2 {
    /* border: 1px solid red; */
    width: 50%;
   
    display: flex;
    justify-content: center;
}

.v_courses .box_2 .course_box_2 {
    width: 73%;
    margin: 5%;
    margin-left: -50px;
    border-radius: 0.5rem;
    border: 1px solid #E2E8F0;
}

.v_courses .box_2 .course_box_2 .course_box_2_image {
    width: 192px;
    height: 192px;
    border-radius: 50%;
    position: relative;
    top: -60px;
    left: 27.5%;
    background-color: white;
    padding: 4px;
    /* border: 1px solid red; */
}

/* ********************** */

.main_program{
    display: flex;
    margin: 70px 0px;
    flex-direction: column;
    /* border: 1px solid red; */
    align-items: center;
   
}
.program{
    display: flex;
    width: 80%;
    justify-content: space-between;
    align-items: center;
    margin-bottom:35px ;
   padding-right: 2rem;
    /* margin-top: 2rem; */
    height: 100%;
    /* padding: 60px 60px 20px 60px; */
    background-color: #fffafb;
    border-radius: 20px;
    /* border: 1px solid red; */
 
}
.textProgram{
    width: 620px;
    height: 380px;
    padding: 5px;
    font-size: 14px;
    margin: 2rem 0rem 2rem 0rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 2rem;
    line-height: 24px;
    /* border:1px solid red; */
    font-family: "Open Sans";
    font-weight: 400;
    color: #544d4f;


}

.textProgram h2 {
    width: 100%;
    word-wrap: break-word;
    height: 120px;
    font-family: poppins;
    font-size: 30px;
    text-align: center;
    line-height: 40px;
    /* border: 1px solid red; */
    color:#0a0103;
    font-weight: 700; 
    margin-bottom: 30px;
}
.textProgram p{
    text-align: center;
}
.programvideo {
    width: 620px;
    height:380px;
    margin-left: 20px;
    

    /* padding: 0 2rem 0 2rem; */
    display: flex;
    /* align-items: center; */
    /* border: 1px solid red; */
}
#widget2-video{
    width: 100% !important;
    height: 100%;
border-radius: 40px;
    /* border: 1px solid red; */
}


 .ApplyNowButton_2 {
     width: 200px;
     height: 45px;
     font-family: "Open Sans";
     font-size: 14px;
     letter-spacing: 0.7px;
    background-color: #6a58c6;
     color: #ffffff;
     font-weight: 600;
     line-height: 24px;
     display: flex;
     justify-content: center;
     align-items: center;
     border-radius: 8px;
     text-transform: uppercase;
     margin: 0px 15px;
     /********/
 }

    .ApplyNowButton_2:hover {
        background-color: #5E17EB;

    }

/********************************/



/********************************/
.Instructors {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

}

.Instructors_top {
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 30px;

}

/* .Instructors_top h1 {
    width: 95%;
    font-size: 35px;
    font-family: Poppins,
        ui-sans-serif,
        system-ui,
        -apple-system,
        BlinkMacSystemFont,
        "Segoe UI",
        Roboto,
        "Helvetica Neue",
        Arial,
        "Noto Sans",
        sans-serif,
        "Apple Color Emoji",
        "Segoe UI Emoji",
        "Segoe UI Symbol",
        "Noto Color Emoji";
        font-weight: 700;
        color: #0a0103;
        font-size: 32px;
        line-height: 32px;
} */

.Instructors_top p {
    width: 80%;
    font-size: 14px;
    line-height: 24px;
    font-family: "Open Sans";
    font-weight: 400;
    margin-top:1rem;
    color:#6b7280;
}
.vertical-dots-l {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    margin: 20px 0; 
  }
  .dot-l {
    width: 40px; 
    height: 40px;
    background-color: #FFBD59; 
    border-radius: 50%;
    margin: 10px 0; 
    position: relative;
  }
  .dot-l:not(.no-line-l)::before {
    margin-top: 0;
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: 2px; /* Adjust the width of the line */
    height: 60px; /* Adjust the height of the line */
    background: repeating-linear-gradient(to bottom, #FFBD59, #FFBD59 2px, transparent 2px, transparent 4px);
  }
  

.Instructors_profiledes {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;

}
.Instructors_profile:hover{
    box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
}
.Instructors_profile {
    width: 230px;
    border-radius: 25px;
    overflow: hidden;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 15px;
    padding-bottom:30px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
        rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
 
}

.Instructors_profile img {
    width: 200px;
}
.Instructors_profile h3{
    margin-top: 14px;
    color: #0a0103;
    font-size: 20px;
    line-height: 34px;
    font-weight: 600;
    font-family: "Open Sans";
}

.Instructors_profile p{
        margin-top: 5px;
        color: #0a0103;
        font-size: 14px;
        line-height: 22px;
        font-weight: 400;
        font-family: "Open Sans";
}

.solid_virtual{
    border-top: 1px solid #e2e8f0;
    width: 50%;
    margin-top: 20px;
    margin-top: 7px;
}
.Instructors_company img {
    height: 40px;
    width: 130px;

}

.seemoreButton {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;
}

.seemoreButton button {
    width: 130px;
    height: 45px;
    font-size: 14px;
    font-family: "Open Sans";
    line-height: 24px;
    letter-spacing: 1.25px;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
    background-color: #ed0331;
    border: none;
    border-radius: 6px;
    font-weight: 700;
}
.seemoreButton button:hover{
    background-color: #c53030;
}



/* Media Queries */
@media screen and (max-width: 1520px) {
        .appyandheading h1 {
            width: 100%;
            /* margin-bottom: 1.5%; */
            }
                .appyandheading h2 {
                    width: 100%;
                    margin-bottom: 1.5%;
                }

    }

 @media screen and (max-width: 1109px) {
.v_courses{
   
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.program{
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
    padding-right: 0;
    padding-bottom: 2rem;
}
.textProgram{
    width: 90%;
    margin-bottom: 2rem;
    /* border: 1px solid red; */
}
.textProgram h2{
    margin-top: 0px !important;
    /* border: 1px solid red; */
 
}
/* .textProgram p{
    font-size: 1.3rem;
    
} */
.programvideo{
    width: 90%;
    margin: 0;
    /* margin-bottom: 2rem; */
}

.box_1 .course_box_1
{
    margin-right: 5% !important;
}

.box_2 .course_box_2 {
    margin-left: 5% !important;
}

.textVSC{
 font-size: 2rem;
}
.main_program{
    margin-top: 70px;
}
    
}

@media screen and (max-width: 989px) {
        .appyandheading h2 {
        margin-bottom: 3%;
        }
       

    .PictureSection {
      margin-top: 2rem; 
       height: 15rem;
       /* border: 1px solid red; */
                    
    }
}

@media screen and (max-width: 830px) {
    .appyandheading h1 {
        margin-top: 2rem;
      /* font-size: 3rem; */
      /* height: 150px; */
      /* line-height: 40px; */
    }
          .appyandheading h2 {
            /* margin-top: 2rem; */
                /* font-size: 3rem; */
                /* height: 150px; */
                line-height: 40px;
          }
         .appyandheading .p1{
            margin-top: 0;
         }
         .appyandheading .pNew{
            margin-top: 10px;
         }
    .textInside {
        margin-top: -2rem;
    }
         .appyandheading .p2 {
             margin-top: 10px;
    
         }

}

@media screen and (max-width: 640px) {
.Enrolled{
width: 100%;
font-size: 2rem;
}
.Enrolled img{
    width: 25%;
    margin-left: 10px;
}
.lowestLPA{
width: 50%;
font-size: 1.5rem;
}
.heightLPA{
    width: 50%;
    font-size: 1.5rem;
}
  .textProgram {
      padding: 2rem;
  }
}



@media screen and (max-width: 605px) {
.appyandheading h1 {
      font-size: 1.8rem;
      /* border: 1px solid red; */
      /* height: 130px; */
      margin-bottom: 0;
      padding-bottom: 0;
    }
        .appyandheading h2 {
            font-size: 1.8rem;
                /* border: 1px solid red; */
                /* height: 130px; */
                /* margin-bottom: 0; */
                /* padding-bottom: 0; */
        }
     /* .textProgram h2 {
             font-size: 1rem;
         } */
}
@media screen and (max-width: 504px) {
    .textVSC {
     font-size: 30px;
   
  }
.Enrolled img {
       position: absolute;
       bottom: 2.5rem;
       margin-left: 10rem;
    }
    .box_1 .course_box_1 {
       width: 50% !important;
       margin-top: 90px !important;
    }
     .v_courses .box_1 .course_box_1 .course_box_1_image{
            left: 21%;
     }
    .box_2 .course_box_2 {
               width: 50% !important;
            
    }
    
    .v_courses .box_2 .course_box_2 .course_box_2_image {
               left: 21%;
    }
    /* .main_program{
        margin-top: 920px;
    }
   */
    /* .textProgram h2{
            font-size: 0.8rem;
    } */
   
}

@media screen and (max-width: 478px) {
  .appyandheading .p1{
    margin-top: 30px;
  }
  .appyandheading .p2{
    margin-top: 1rem;
  }
  .appyandheading .pNew{
    margin-top: 20px;
 }
 .Instructors_profile{
    width: 300px;
 }
 .Instructors_profile img {
    width: 280px;
}


}
@media screen and (max-width: 438px) {
    .appyandheading h1 {
           padding-bottom: 0.5rem;
        }
                  .appyandheading h2 {
                      padding-bottom: 2rem;
                  }
            
        .appyandheading .p1 {
                margin-top:0rem;
                }
/* .textProgram h2 {
    font-size: 1.5rem;
    } */
    .appyandheading .p2 {
             margin-top: 3rem;
    }
}
@media screen and (max-width: 405px){
    .appyandheading{
        height:100%
    }
    .appyandheading h1 {
        margin-top: 4rem;
            padding-bottom: 0.5rem;
        }
                 .appyandheading h2{
                    padding-bottom: 0.2rem;
                 }
.appyandheading .p1 {
        margin-top: 2rem;
    }
}
@media screen and (max-width: 345px) {
    .appyandheading .p2 {
        margin-top: 4rem;
    }

         .box_1 .course_box_1 {
             width: 40% !important;
             margin-top: 90px !important;
         }
    
         .v_courses .box_1 .course_box_1 .course_box_1_image {
             left: 15% ;
         }
    
         .box_2 .course_box_2 {
             width: 40% !important;
    
         }
    
         .v_courses .box_2 .course_box_2 .course_box_2_image {
             left: 15%;
         }
        .main_program {
            margin-top: 70px;
        }
}
@media (max-width : 1025px){
    .startup-cases{
     width: 80%;
    }
 }
 @media (max-width : 768px){
    .startup-container{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
      }
      .startup-cases{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
       
      }
      .startup-out{
        background-color: white;
        border-radius: 25px;
        width: 100%;
      }
      .startup-boxes{
        display: flex;
        justify-content: space-evenly;
        
      }
 }