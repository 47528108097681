@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600&family=Poppins:wght@600;700&display=swap');

.kickstart {
    background-color: white;
    width: 100%;
    margin-top: 5rem;
    overflow: hidden;
}

.poster{
    background-image: url("../components//NewVirtualCampusStartup/Images/gradient.svg");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    /* border:1px solid red */
    /* margin-top: 0.8rem; */
}


.poster_containt{
    width:100%;           /*  Important */
    height: 100%;         /*  Important */
    display: flex;
   
    /* border:1px solid red; */
}

.poster_text{
    /* border:1px solid red; */
    width: 55%;
    height: 100%;
    display: flex;
    margin-top: 5.5rem;
    align-items: center;
}

.poster_img{
    /* border:1px solid red; */
    width: 50%;
    height: 500px;
     display: flex;
     margin-left: 50px;
    object-fit: fill;
    flex-direction: column-reverse;
  
}
.poster_img img{
    width: 100%;
    /* margin-left: -2rem; */
    height: 100% !important;
    margin-inline-start: auto;
    margin-top: 7rem;
  
    animation: kickstart 1.7s linear infinite alternate;
    /* border: 1px solid orange; */
}
@keyframes kickstart {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(-10%);
    }
}

.poster_text .text_box{
    width: 100%;
    height: 75%;
    /* border: 1px solid red; */
}

#first_text{
    color: #1A202C;
    font-family: "Poppins", sans-serif;
    font-size: 48px;
    line-height: 62px;
    font-weight: 800;
    margin: 16px 0 0;
}

#second_text{
    color: #3470E4;
    font-weight: 700;
    line-height: 24px;
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    margin: 12px 0 0;
}

#third_text {
    color: #3470E4;
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    margin: 3px 0 0;
}

#fourth_text{
    color: #6C6768;
    margin: 16px 0px 0px;
    font-size: 16px;
    font-family: "Open sans", sans-serif;
}

.poster_text .text_box .undergrad_box{
    height: 10%;
    margin-top: 23px; 
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%; 
    /* border: 1px solid red; */
}

#fifth_text{
    color:#3470E4;
    font-family: "Open sans", sans-serif;
    font-size: 12px;
    background-color: #F2F6FF;
    margin: 16px 8px 0px;
    padding: 8px 12px;
    border-radius: 100px;
}

#sixth_text{
    color: #FFFFFF;
    font-family: "open sans", sans-serif;
    font-size: 14px;
    background-color: #6a58c6;
    margin: 24px 0px 0px;
    text-align: center;
    padding: 0px 16px;
    border-radius: 8px;
    height: 45px;
    border: none;
    margin-top: 30px;
}

#sixth_text:hover{
    cursor: pointer;
    background-color: #cc032a;
}




/* ********************* */

.why_kickstart{
    /* border: 1px solid red; */
    width: 100%;
    height: 100%;
    padding: 48px 0px;
}

.why_kickstart h2{
    color: #0A0103;
    font-family: "Poppins";
    font-size: 34px;
    font-weight: 700;
}

.kickstart_box{
    /* border: 1px solid red; */
    margin-top: 2rem;
}

.box1{
    display: flex;
    /* border: 1px solid red; */
    justify-content: space-between;
    padding-bottom: 2rem;
    margin-right: 2rem;
   
}

.box1 .box1_right{
 
    /* border:1px solid orange; */
    margin-left: 2rem;
}
.box2 .box2_right {
 
    /* border: 1px solid orange; */
    margin-left: 2rem;
}
.box2{
    display: flex;
    /* border: 1px solid red; */
    justify-content: space-between;
    margin-right: 2rem;
}

.box3 {
    display: flex;
    /* border: 1px solid red; */
    justify-content: space-between;
    padding-top: 2rem;
    margin-right: 2rem;

}

.box3 .box3_right {

    /* border:1px solid orange; */
    margin-left: 2rem;
}

/* ******************** */

.requirement{
    /* border: 1px solid red; */
    width: 100%;
    height: 100%;
    padding: 48px 0px;
}


.requirement_box{
    /* border: 1px solid red; */
    margin-top: 2rem;
}

.requirement h2{
        color: #0A0103;
        font-family: "Poppins";
        font-size: 34px;
        font-weight: 700;
}


/* **************************** */

.kickstart_courses{
        /* border: 1px solid red; */
        width: 100%;
        height: 100%;
        padding: 40px 0px;
       
}

.kickstart_courses h2{
    color: #0A0103;
    font-size: 34px;
    font-family: Poppins,
        ui-sans-serif,
        system-ui,
        -apple-system,
        BlinkMacSystemFont,
        "Segoe UI",
        Roboto,
        "Helvetica Neue",
        Arial,
        "Noto Sans",
        sans-serif,
        "Apple Color Emoji",
        "Segoe UI Emoji",
        "Segoe UI Symbol",
        "Noto Color Emoji";;
    font-weight: 700;
    line-height: 51px;
}

.level_courses{
    display: flex;
    margin-right: 10px;
    margin-top: 3rem;
    /* border: 1px solid red; */
}
.course_1{
    /* border: 1px solid red; */
 
    box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.05);
    width: 30%;
    border: 1px solid rgb(229, 228, 228);
    margin-right:4rem;
    border-radius: 18px;
}

.course_2 {
    box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.05);
        width: 30%;
        border: 1px solid rgb(229, 228, 228);
        margin-right: 4rem;
        border-radius: 18px;
}
.course_3 {
    box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.05);
        width: 30%;
        border: 1px solid rgb(229, 228, 228);
  
        border-radius: 18px;
        /* border: 1px solid red; */
}

.course_1 .course_data{
    width: 93%;
    /* border: 1px solid red; */
    margin: 10px;
}


.course_2 .course_data {
    width: 93%;
    /* border: 1px solid red; */
    margin: 10px;
}

.course_3 .course_data {
    width: 93%;
    /* border: 1px solid red; */
    margin: 10px;
} 

.course_1 .course_1_button{
    /* border: 1px solid red; */
    margin: 16px 0px;
    display: flex;
   justify-content: flex-end;
   padding: 8px 10px;
}

.course_2 .course_2_button {
    /* border: 1px solid red; */
    margin: 16px 0px;
    display: flex;
    justify-content: flex-end;
    padding: 8px 10px;
}

.course_3 .course_3_button {
    /* border: 1px solid red; */
    margin: 16px 0px;
    display: flex;
    justify-content: flex-end;
    padding: 8px 10px;
}







.course_1 .course_1_button button{
  padding: 12px 16px;
  font-size: 14px;
  font-family: "Open sans", sans-serif;
  background-color: #ED0331;
  color: #FFFFFF;
  border-radius: 8px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 0.7px;
}
.course_1 .course_1_button button:hover{
    cursor: pointer;
    background-color: #dc2626;
}

.course_1 .course_1_button .details{
        padding: 12px 16px;
        font-size: 14px;
        font-family: "Open sans", sans-serif;
        background-color: #FFFFFF;
        color: #ED0331;
        font-weight: 600;
        border-radius: 8px;
        border: 1px solid red;
        margin-right: 0.6rem;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        text-align: center;
        letter-spacing: 0.7px;
}
.course_1 .course_1_button .details:hover{
    cursor: pointer;
    background-color:  rgba(254, 242, 242,1);
}
.course_2 .course_2_button button {
    padding: 12px 16px;
    font-size: 14px;
    font-family: "Open sans", sans-serif;
    background-color: #ED0331;
    color: #FFFFFF;
    border-radius: 8px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 0.7px;
}

.course_2 .course_2_button button:hover {
    cursor: pointer;
    background-color: #dc2626;
}

.course_2 .course_2_button .details {
    padding: 12px 16px;
    font-size: 14px;
    font-family: "Open sans", sans-serif;
    background-color: #FFFFFF;
    color: #ED0331;
    font-weight: 600;
    border-radius: 8px;
    border: 1px solid red;
    margin-right: 0.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 0.7px;
}

.course_2 .course_2_button .details:hover {
    cursor: pointer;
    background-color: rgba(254, 242, 242, 1);
}


.course_3 .course_3_button button {
    padding: 12px 16px;
    font-size: 14px;
    font-family: "Open sans", sans-serif;
    background-color: #ED0331;
    color: #FFFFFF;
    border-radius: 8px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 0.7px;
}

.course_3 .course_3_button button:hover {
    cursor: pointer;
    background-color: #dc2626;
}

.course_3 .course_3_button .details {
    padding: 12px 16px;
    font-size: 14px;
    font-family: "Open sans", sans-serif;
    background-color: #FFFFFF;
    color: #ED0331;
    font-weight: 600;
    border-radius: 8px;
    border: 1px solid red;
    margin-right: 0.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 0.7px;
}

.course_3 .course_3_button .details:hover {
    cursor: pointer;
    background-color: rgba(254, 242, 242, 1);
}
@media screen and (max-width: 1100px) {
    #first_text{
        font-size: 35px;
    }
    #second_text{
        font-size: 16px;
    }
    #third_text {
             font-size: 16px;
    }
    #fourth_text {
             font-size: 16px;
    }
    #fifth_text {
             font-size: 14px;
    }
    #sixth_text {
      font-size: 14px;
    }
}
@media screen and (max-width:988px){
    .level_courses {
            flex-direction: column;
            /* border: 1px solid red; */
        }
    
        .course_1 {
            width: 100%;
        }
    
        .course_2 {
            width: 100%;
            margin-top: 2rem;
        }
    
        .course_3 {
            width: 100%;
            margin-top: 2rem;
        }
}

@media screen and (max-width:815px) {
  .poster_containt{
    flex-direction: column;
  }
  .poster_text{
    width: 100%;
    /* border: 1px solid red; */
    margin-top: 30px;
    text-align: center;
  }
  .undergrad_box{
    align-items: center;
    justify-content: center;
  }
  .poster_img{
   width: 100%;
    background-image: url("../components//NewVirtualCampusStartup/Images/gradient.svg");
    /* border: 1px solid red; */
    margin: 20px 0px 0px 0px;
    height: 100%;
   flex-direction: column;
  }
  .poster_img img{
    /* border: 1px solid orange; */
    margin-top: 0%;

  }
  #first_text {
      font-size: 40px;
  }

  #second_text {
      font-size: 16px;
  }

  #third_text {
      font-size: 16px;
  }

  #fourth_text {
      font-size: 14px;
  }

  #fifth_text {
      font-size: 12px;
  }

  #sixth_text {
      font-size: 14px;
  }


  .box1{
    flex-direction: column;

  }
  .box1 .box1_right{
    margin-left: 0;
 
    margin-top: 2rem;
  }
   .box2 {
       flex-direction: column;
   }

   .box2 .box2_right {
       margin-left: 0;
       margin-top: 2rem;
   }
   .box3 {
            flex-direction: column;
    }
    
    .box3 .box3_right {
            margin-left: 0;
            margin-top: 2rem;
    }

   
}