.profile-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
  width: 100%;
}

.profile-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.profile-image {
  padding: 2%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  margin-left: 20px;
  width: '40%';
}

.profile-image img {
  border-radius: 50%;
  width: 120px;
  height: 120px;
  /* border: 2px solid #fff; */
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 3px;
}

.profile-details {
  flex-grow: 1;
  width: '60%';
  margin-right: 10px;
}

.profile-details h2 {
  margin-bottom: 10px;
  color: '#fff';
}

.profile-details p {
  margin-bottom: 8px;
  color: '#fff';
}

.social-icons {
  display: flex;
  gap: 10px;
  margin-top: 10px;
  justify-content: flex-end;
  align-items: flex-end;
}

.social-icons a {
  color: '#fff';
  font-size: 24px;
  transition: color 0.3s ease-in-out;
}

.social-icons a:hover {
  color: #007bff;
}

.details-section {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.personal-details,
.professional-details {
  flex-grow: 1;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  margin-right: 20px;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.details-section h3 {
  color: #333;
  border-bottom: 2px solid #ddd;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.details-section h4 {
  color: #333;
  margin-bottom: 10px;
}

.details-section p {
  color: #666;
  margin-bottom: 8px;
}

.interests-and-other {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.interests-and-other h3 {
  color: #333;
  border-bottom: 2px solid #ddd;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.interests-and-hobbies,
.social-media-links {
  margin-bottom: 20px;
}

.profile-pic-row {
  border-radius: 10px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  background-image: url(../Images/Profile_Background.jpg);
  box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
}
.statistics-section {
  flex-grow: 1;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;

}

.stat-boxes {
  display: flex;
  justify-content: space-between;
}

.stat-box {
  flex-grow: 1;
  text-align: center;
  padding: 10px;
  background-color: #fff;
  /* border: 1px solid #ddd;
   */
   box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
  border-radius: 8px;
  /* margin-right: 10px;
   */
   width: 48%;
   height: 50;
   margin: 2px;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
}
.stat-box:hover{
  transform: scale(1.2);
}

.stat-box strong {
  display: block;
  font-size: 24px;
  margin-bottom: 5px;
  font-weight: bold;
}

.stat-box p {
  margin: 0;
  color: #666;
}
.progress-indicator {
  flex-grow: 1;
  padding: 20px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
 
  border-radius: 8px;
}

.progressbar-container {
  /* width: 100px; Adjust the width as needed/ */
  width: 150px;
  height: 150px;
  margin: 20px auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
 @media (max-width: 600px) {
    .profile-pic-row{
      flex-direction: column;
    }
    .social-icons{
      justify-content: center;
    }
    .profile-image img{
      width: 100px;
      height: 100px;
    }
    .details-section{
      flex-direction: column ;
    }
    .personal-details{
      margin-bottom: 20px;
      width: 100%;
    }
    .professional-details{
      margin-bottom: 20px;
      width: 100%;
    }
 }