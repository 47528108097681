.career-main-page{
    margin-top:8rem;
    width: 100%;
    height: 100%;
    display: flex;
}
.career-page1{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 600px;
}

.career-page2{
width: 50%;
height: 100vh;
margin-right: 4%;
} 

@media screen and (max-width: 600px) {
    .career-page2{
        height:'100%'
    }
}