.cards{
    display: flex;
    justify-content: space-evenly;
    margin-top: 25px;
}

.card{
    height: 350px;
    width: 25%;
    border-radius: 25px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); 
    color: black;
    cursor: pointer;
}

.card:hover{
    background-color: #6A58C6;
    transform: scale(1.05);
    transition: transform 0.3s ease-in-out;
}

@media screen and (max-width: 768px){
  .cards{
    display: flex;
    flex-direction: column;
    /* justify-content: space-evenly;
     */
     justify-content: center;
     align-items: center;
    margin-top: 25px;
}
.card{
  height: 350px;
  width: 80%;
  border-radius: 25px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); 
  color: black;
  cursor: pointer;
  margin-bottom: 20px;
}
}
 .course_card{
    transition: transform 0.3s ease;
    /* box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset; */
  }
  
  .course_card:hover {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    transform: scale(1.2);
  }
  .square {
     transform: rotateY(45deg);
  
     /* transform: scale3d(0.5, 1, 1.7); */
    animation: rotateAnimation 20s linear infinite;
  }
  .courses-main-d{
    width: 100%;
     height: 100%;
     /* border: 1px solid red; */
     display: flex;
     flex-direction: column;
     align-items: center;
  }
  .main-heading_virtual-d{
    /* border: 1px solid red; */
    width: 50%;
    height: 18%;
    display: flex;
    align-items: center;
    flex-direction: column;
 }
 .main-heading_virtual-d p {
  color: #3b3435;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  font-family: "Open Sans";
  margin: 5px 0px 0px;
  text-align: center;
}
.v_courses-d{
  /* border:1px solid red; */
 
  width: 100%;
  display: flex;
  /* border: 1px solid red; */
}
.v_courses-d .box_1-d{
  /* border: 1px solid red; */
  width: 50%;
  display: flex;
  justify-content: center;
  
}

.v_courses-d .box_1-d .course_box_1-d{
    width: 80%;
    height: auto;
    margin: 5%;
    /* margin-right: -50px; */
    border-radius: 0.5rem;
    border: 1px solid #E2E8F0;
}

.v_courses-d .box_1-d .course_box_1-d .course_box_1_image-d{
          width: 192px;
          height: 192px;
          border-radius: 50%;
          position: relative;
          top: -60px;
          left: 27.5%;
          background-color: white;
          padding: 4px;
}

.v_courses-d .box_2-d {
  width: 50%;
  display: flex;
  justify-content: center;
}

.v_courses-d .box_2-d .course_box_2-d {
  width: 83%;
  margin: 5%;
  /* margin-left: -50px; */
  border-radius: 0.5rem;
  border: 1px solid #E2E8F0;
}

.v_courses-d .box_2-d .course_box_2-d .course_box_2_image-d {
  width: 192px;
  height: 192px;
  border-radius: 50%;
  position: relative;
  top: -60px;
  left: 27.5%;
  background-color: white;
  padding: 4px;
  /* border: 1px solid red; */
}
.textVSC-d{
  color:#010A03;
  font-size: 48px;
  font-weight: 700;
  line-height: 40px;
  font-family:"Poppins",
      ui-sans-serif,
      system-ui,
      -apple-system,
      BlinkMacSystemFont,
      "Segoe UI",
      Roboto,
      "Helvetica Neue",
      Arial,
      "Noto Sans",
      sans-serif,
      "Apple Color Emoji",
      "Segoe UI Emoji",
      "Segoe UI Symbol",
      "Noto Color Emoji";
      color: #6A58C6;
      margin: 18px 0px 24px 0px ;
      text-align: center;

}
@media screen and (max-width: 1109px) {
.textVSC-d{
  font-size: 24px;
 }
 .v_courses-d{
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
}
@media screen and (max-width: 844px){
  .v_courses-d .box_1-d {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .box_1-d .course_box_1-d {
    width: 60% !important;
 }
  .v_courses-d .box_2-d {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .box_2-d .course_box_2-d {
    width: 60% !important;
 }
}
 @media screen and (max-width: 504px) {
  .textVSC-d {
   font-size: 30px;
 
}
.v_courses-d .box_1-d {
  width: 100%;
  display: flex;
  justify-content: center;
}
.box_1-d .course_box_1-d {
  width: 100% !important;
}
.v_courses-d .box_2-d {
  width: 100%;
  display: flex;
  justify-content: center;
}
.box_2-d .course_box_2-d {
  width: 100% !important;
}
/* .box_1-d .course_box_1-d {
  width: 70% !important;
  margin-top: 90px !important;
} */
.v_courses-d .box_1-d .course_box_1-d .course_box_1_image-d{
       left: 21%;
}
/* .box_2-d .course_box_2-d {
          width: 50% !important;
       
} */

.v_courses-d .box_2-d .course_box_2-d .course_box_2_image-d {
          left: 21%;
}
}
@media screen and (max-width: 504px) {
  /* .box_1-d .course_box_1-d {
    width: 40% !important;
    margin-top: 90px !important;
  } */
  
  .v_courses-d .box_1-d .course_box_1-d .course_box_1_image-d {
    left: 15% ;
  }
/*   
  .box_2-d .course_box_2-d {
    width: 40% !important;
  
  }
   */
  .v_courses-d .box_2-d .course_box_2-d .course_box_2_image-d {
    left: 15%;
  }
}

  
  @keyframes rotateAnimation {
    from {transform: rotateY(45deg);}
    to {transform: rotateY(225deg);}
  }

