.Login_Form{
     margin-top:7rem;
   
    font-family: 'Open Sans', sans-serif!important;

    padding-top: 6% ;
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: -10rem;
    /* background: linear-gradient(to top left, #6A58C6, #a8f5ff) !important; */
    overflow-y: hidden;
    /* border:1px solid red; */
    height: 70vh;
}

.left_box {
    width: 100% !important;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 10px;
    margin-top: 1rem;
    font-size: 4rem;
    padding: 3rem;
    color: white;
    font-family: "Spartan", sans-serif !important;
}

.titleLogin {
    animation-name: moveRight;
    animation-duration: 0.7s;
    color: #000000;
}

.titleTwoLogin {
    animation-name: moveLeft;
    animation-duration: 1s;
    color: #000000;
}

.titleThreeLogin {
    animation-name: moveRight;
    animation-duration: 1.3s;
     color: #000000;
    margin-bottom: 10px;
}

.right_box {
    width: 100%;
}

.login_form {
    background: linear-gradient(to top left, #ffffffff, #ffffff33);
    backdrop-filter: blur(15px);
    border-radius: 10px;
    width: 60%;
    margin: auto;
    margin-top: 5%;
    box-shadow: 2px 2px 5px #6A58C6;
    z-index: 2;
    padding: 5%;
}

.login_form>h3 {
    text-align: center;
    font-family: 'Poppins';
}

.auth_button {
    cursor: pointer !important;
    width: 100%;
}

.auth_button .button_login {
    background-color:#6A58C6;
    color: white;
    width: 100%;
    padding: 1rem;
    font-size: 1rem;
    font-weight: 600;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    margin-top: 0rem;

}

.auth_button .button_login:hover {
    background-color: #2773a5;
}

.error {
    color: red;
}

.Box {
    margin-top: 4rem;
}

.not_member {
    font-size: 0.8rem !important;
}

.textLogin {
    font-size: 40px;
    color: #6A58C6;
    font-weight: 600;
    margin-bottom: 20px;
}


@keyframes moveRight {
    0% {
        opacity: 0;
        transform: translateX(100px);
    }

    100% {
        opacity: 1;
        transform: translate((0));
    }
}

@keyframes moveLeft {
    0% {
        opacity: 0;
        transform: translateX(-150px);
    }

    100% {
        opacity: 1;
        transform: translate((0));
    }
}

@keyframes moveUp {
    0% {
        opacity: 0;
        transform: translateY(50px);
    }

    100% {
        opacity: 1;
        transform: translate((0));
    }
}

@media only screen and (max-width: 1280px) {
.Login_Form{
    height: 100vh;
}

    .login_form {
        width: 90%;
    }

    .textLogin {
        display: flex;
        width: 90vw;
        font-size: 2rem;
        margin-left: -40px;
    }

    .main-heading_vsc {
        font-size: 2.5rem;
        margin-left: -45px;
        
    }

    .left_box {
        padding-top: 1rem !important;
    }
}

@media screen and (max-width:700px) {


    .login_form {
        width: 90%;
    }

    .textLogin {
        display: flex;
        width: 90vw;
        /* font-size: 1rem; */
        margin-left: -40px;
    }

    .main-heading_vsc {
        font-size: 2.5rem;
        margin-left: -45px;
        
    }

    .left_box {
        padding-top: 1rem !important;
    }
}

@media only screen and (max-width: 420px) {
    .Login_Form {
       
        padding-top: 4rem;
    }

    .login_form {
        width: 90%;

    }

    .textLogin {
        display: flex;
        width: 90vw;
        margin-left: -40px !important;
        font-size: 1.2rem;
    }

    .main-heading_vsc {
        font-size: 2.5rem;
    }
}

@media only screen and (max-width: 320px) {

    .login_form {
        width: 90%;
    }
    .textLogin {
             
      font-size: 1.2rem;
  }

    .main-heading_vsc {
        font-size: 1.8rem;

    }

    .left_box {
        margin-left: -1rem !important;
        margin-bottom: auto;

    }
}


/* The code is very responsive and I did not write the confusing classnames */