.animated-card {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.5s ease, transform 0.5s ease;
  }
  
  .animated-card.fadeInUp {
    opacity: 1;
    transform: translateY(0);
  }
  