.Maincontainer{
    margin-top: 120px;
    background-color: white;
    font-family: "Poppins", sans-serif;
}
.container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 80px;
  }
  
  .left-real-time {
    color: #6A58C6;
    font-size: 75px;
    margin-bottom: -25px;
  }
  .left-real-time-2 {
    font-size: 75px;
    margin-bottom: -25px;
  }
  .left-real-time-4 {
    font-size: 75px;
    
  }
  .left-real-time-3 {
    font-size: 25px;
    margin-bottom: 5px;
  }

  .number{
    font-size: 25px;
  }
  .container-button {
    background-color:#6A58C6;
    color: white;
    border: none;
    height: 50px;
    width: 500px;
    font-size: 30px;
    margin-top: 10px;
    padding: 10px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  
  .right-section {
    display: flex;
    flex-direction: column;
  }
  
  .box {
    padding: 20px;
    margin-bottom: 10px;
    position: relative;
  }
  
  .box-right-1 {
    background-color: #6A58C6;
    color: white;
    height: 150px;
    width: 200px;
    border-radius: 25px;
    margin-right: 40px;
    margin-top: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-bottom: 10px;
    position: relative;
  }
  
  .box-right-2 {
    margin-top: -70px;
    margin-left: 350px;
    background-color: #FFBD59;
    border-radius: 25px;
    height: 150px;
    width: 200px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-bottom: 10px;
    position: relative;
  }
  
  .box-right-3 {
    margin-left: 90px;
    width: 250px;
    background-color: #7AFFC9;
    border-radius: 25px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-bottom: 10px;
    position: relative;
  }
  
  .box h1,
  .box h6 {
    margin: 0;
  }
  
  .box div {
    position: absolute;
    bottom: 10px;
    right: 10px;
    font-size: 40px;
  }
  .startup-container{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .startup-cases{
    margin-top: 50px;

    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    /* padding: 2; */
  
  }
  .startup-out{
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 50px;
    background-color: #f6f6f6;
    padding:'2%';
    border-radius: 25px;
    height: 100%;
    width: 90%;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
  }
  .centered-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 75px; 
    text-align: center;
    font-size: 25px;
    margin-bottom: 50px;
  }
  
  .boxes-1{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 28px;
    text-align: center;
    font-weight: bold;
    height: 150px;
    width: 150px;
    background-color: #6A58C6;
    border-radius: 25px;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  }
  .boxes-2{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    text-align: center;
    font-weight: bold;
    height: 150px;
    width: 150px;
    background-color: #FFBD59;
    border-radius: 25px;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;

  }
  .boxes-3{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    text-align: center;
    font-weight: bold;
    height: 150px;
    width: 150px;
    background-color: #7AFFC9;
    border-radius: 25px;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;

  }
  .boxes-4{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    text-align: center;
    font-weight: bold;
    height: 150px;
    width: 155px;
    background-color: #FF5757;
    border-radius: 25px;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;

  }
  .boxes-5{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    text-align: center;
    font-weight: bold;
    height: 150px;
    width: 150px;
    background-color: #6A58C6;
    border-radius: 25px;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;

  }
  .boxes-6{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 25px;
    font-weight: bold;
    height: 150px;
    width: 150px;
    background-color: #FFBD59;
    border-radius: 25px;
    cursor: pointer;
  }
  .boxes-7{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 25px;
    font-weight: bold;
    height: 150px;
    width: 150px;
    background-color: #7AFFC9;
    border-radius: 25px;
    cursor: pointer;
  }
  .cases-bar{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f6f6f6;
    border-radius: 25px;
    height: 150px;
    width: 1250px;
    justify-content: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  }
  .cases-box{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    text-align: center;
    transition: opacity 1s ease-in-out;

  }
  .cases-box.hidden {
    opacity: 0;
  }
  .cases-button{
    display: flex;
    justify-content: center;
    margin-top: -15px;
  }
  .selected {
    color: black; 
    background-color: #f0f0f0; 
  }
  
  .cases-button button{
    background-color: #6A58C6;
    color: white;
    text-align: center;
    width: 170px;
    height: 40px;
    border-radius: 25px;
    border: none;
    cursor: pointer;
    font-size: 25px;
    font-weight: bold;
    
  }
  .real-time{
    display: flex;
    align-items: center;
  }

  .project-real-time {
    display: flex;
    justify-content: center;
  }
  
  .project-cases {
    display: flex;
    justify-content: space-evenly;
    height: 500px;
    width: 1250px;
    border-radius: 50px;
    background-color: #251E4F;
  }
  
  .project-window {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  
  .window-video {
    height: 320px;
    width: 570px;
    border-radius: 50px;
  }
  
  .window-button {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .window-button button {
    width: 500px;
    border: none;
    height: 50px;
    border-radius: 25px;
    background-color: #6A58C6;
    color: white;
    font-size: 30px;
    cursor: pointer;
  }
  
  .project-buttons {
    display: flex;
    flex-direction: column;
    justify-content: center; /* Adjusted to center the buttons vertically */
  }
  
  .project-button {
    height: 70px;
    font-size: 30px;
    width: 400px;
    border-radius: 25px;
    border: none;
    margin-bottom: 10px;
    cursor: pointer;
    color: black;
    font-size: 25px;
  }
  
  .project-button:nth-child(1) {
    background-color: #6A58C6;
  }
  
  .project-button:nth-child(2) {
    background-color: #7AFFC9;
  }
  
  .project-button:nth-child(3) {
    background-color: #FFBD59;
  }
  
  .project-button:nth-child(4) {
    background-color: #7AFFC9;
  }
  
  .project-button:nth-child(5) {
    background-color: #6A58C6;
  }
  
  .box-cases {
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-bottom: 50px;
    margin-right: 20px;
    margin-left: 20px;
  }
  .box-steps {
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-bottom: 50px;
  }
  .boxes-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #6A58C6;
    height: 40px;
    width: 120px;
    font-size: 30px;
    border-radius: 20px;
    cursor: pointer;
    color: white;
    transition: background-color 0.3s ease; /* Added transition effect */
  }
  .selected-step {
    background-color: #FFBD59; 
    color: white; 
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); 
  }
  .after-login-screen {
    display: flex;
    justify-content: center;
  }
  
  .after-login-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
  
  .after-login-left-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    color: black;
    height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  
  /* Add some padding to each button to create space between them */
  .after-login-button {
    padding: 10px;
  }
  
  /* Optional: Add some styling to the scrollbar */
  .after-login-left-container::-webkit-scrollbar {
    width: 8px;
  }
  
  .after-login-left-container::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
  }
  
  .after-login-left-container::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }
  
  .after-login-left-container::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }
  
  .after-login-button {
    height: 70px;
    font-size: 19px;
    width: 300px;
    border-radius: 25px 0 0 25px;
    box-shadow: 5px 5px 5px #888888;
    border: none;
    cursor: pointer;
    
  }
  .after-login-button:hover{
    background-color: #f6f6f6;
  }
  
  .after-login-right-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 550px;
    width: 600px;
    background-color: #dedede;
    border-radius: 25px;
    border: 5px solid black;
  }
  
  .content-container {
    width: 90%;
    border-radius: 25px;
    background-color: white;
    height: 480px; /* Set the desired height for the scrollable view */
    overflow-y: auto; /* Add a vertical scrollbar when content overflows */
    padding: 20px; /* Optional: Add padding for better appearance */
  }
  .after-login-button-1{
    background-color: #6A58C6;
    color: black;
  }
  .after-login-button-2{
    background-color: #7AFFC9;
    color: black;
  }
  .after-login-button-3{
    background-color: #FFBD59;
    color: black;
  }
  .after-login-button-4{
    background-color: #6A58C6;
    color: black;
  }
  .after-login-button-5{
    background-color: #7AFFC9;
    color: black;
  }
  .after-login-button-6{
    background-color: #FFBD59;
    color: black;
  }
  .after-login-button-7{
    background-color: #6A58C6;
    color: black;
  }
  .after-login-button-8{
    background-color: #7AFFC9;
    color: black;
  }
  .after-login-button-9{
    background-color: #FFBD59;
    color: black;
  }
  .after-login-button-10{
    background-color: #6A58C6;
    color: black;
  }
  .after-login-button-11{
    background-color: #7AFFC9;
    color: black;
  }
  .after-login-button-12{
    background-color: #FFBD59;
    color: black;
  }
  .after-login-button-13{
    background-color: #6A58C6;
    color: black;
  }
  .after-login-button-14{
    background-color: #7AFFC9;
    color: black;
  }
  .content-container {
    width: 90%;
    border-radius: 25px;
    background-color: white;
    height: 480px; /* Set the desired height for the scrollable view */
    overflow-y: auto; /* Add a vertical scrollbar when content overflows */
    padding: 20px; /* Optional: Add padding for better appearance */
  }
  .center{
    text-align: center;
    display: flex;
  }
  @media (max-width : 1025px){
     .startup-cases{
      width: 80%;
     }
  }

  @media (max-width : 768px){
    .container{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .left-section{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .left-real-time {
      color: #6A58C6;
      font-size: 50px;
      text-align: center;
    }
    .left-real-time-2 {
      font-size: 50px;
      text-align: center;
    }
    .left-real-time-4 {
      font-size: 50px;
      
    }
    .left-real-time-3 {
    
      font-size: 20px;
      text-align: center;
      margin-bottom: 5px;
    }
  
    .number{
      font-size: 25px;
    }
    .container-button {
      background-color:#6A58C6;
      color: white;
      border: none;
      height: 50px;
      width: 350px;
      font-size: 30px;
      margin-top: 10px;
      padding: 10px 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      text-align: center;
    }
    
    .right-section {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
    
    .box {
      padding: 20px;
      margin-bottom: 10px;
    }
    
    .box-right-1 {
      background-color: #6A58C6;
      color: white;
      height: 150px;
      width: 250px;
      border-radius: 25px;
      margin-right: 0;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      
    }
    
    .box-right-2 {
      background-color: #FFBD59;
      border-radius: 25px;
      height: 150px;
      width: 250px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    margin-left: auto;
    margin-right: auto;
    }
    
    .box-right-3 {
      width: 250px;
      background-color: #7AFFC9;
      border-radius: 25px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      margin-top: 20px;
      margin-left: auto;
    margin-right: auto;
    }
    
    .box h1,
    .box h6 {
      margin: 0;
    }
    
    .box div {
      position: absolute;
      bottom: 10px;
      right: 10px;
      font-size: 40px;
    }
    .startup-container{
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
    }
    .startup-cases{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
     
    }
    .startup-out{
      margin-top: 50px;
      background-color: white;
      border-radius: 25px;
      height: 100%;
      width: 380px;
    }
    .startup-boxes{
      display: flex;
      justify-content: space-evenly;
      
    }
    .box-cases {
      margin-top: 50px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      margin-bottom: 50px;
    }
    .box-steps {
      margin-top: 50px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      margin-bottom: 50px;
    }
    .boxes-1{
      display: flex;
      
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 28px;
      text-align: center;
      font-weight: bold;
      height: 100px;
      width: 350px;
      margin: 10px;
      background-color: #6A58C6;
      border-radius: 25px;
      cursor: pointer;
    }
    .boxes-2{
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 25px;
      text-align: center;
      font-weight: bold;
      height: 100px;
      width: 350px;
      margin: 10px;
      background-color: #FFBD59;
      border-radius: 25px;
      cursor: pointer;
    }
    .boxes-3{
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 25px;
      text-align: center;
      font-weight: bold;
      height: 100px;
      width: 350px;
      margin: 10px;
      background-color: #7AFFC9;
      border-radius: 25px;
      cursor: pointer;
    }
    .boxes-4{
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 25px;
      text-align: center;
      font-weight: bold;
      height: 100px;
      width: 350px;
      margin: 10px;
      background-color: #FF5757;
      border-radius: 25px;
      cursor: pointer;
    }
    .boxes-5{
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 25px;
      text-align: center;
      font-weight: bold;
      height: 100px;
      width: 350px;
      margin: 10px;
      background-color: #FFBD59;
      border-radius: 25px;
      cursor: pointer;
    }
    .boxes-6{
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-size: 25px;
      font-weight: bold;
      height: 100px;
      width: 350px;
      margin: 10px;
      background-color: #6A58C6;
      border-radius: 25px;
      cursor: pointer;
    }
    .boxes-7{
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-size: 25px;
      font-weight: bold;
      height: 100px;
      width: 350px;
      margin: 10px;
      background-color: #7AFFC9;
      border-radius: 25px;
      cursor: pointer;
    }
    .cases-bar{
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      background-color: white;
      border-radius: 25px;
      height: 250px;
      width: 370px;
      justify-content: center;
    }
    .cases-box{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 15px;
      transition: opacity 1s ease-in-out;
  
    }
    .cases-box.hidden {
      opacity: 0;
    }
    .cases-button{
      display: flex;
      justify-content: center;
      margin-top: -5px;
    }
    .cases-button button{
      background-color: #6A58C6;
      color: white;
      text-align: center;
      width: 170px;
      height: 40px;
      border-radius: 25px;
      border: none;
      cursor: pointer;
      font-size: 25px;
      font-weight: bold;
      
    }
    .project-real-time {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    
    .project-cases {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      height: 800px;
      width: 370px;
      border-radius: 50px;
      background-color: #251E4F;
    }
    
    .project-window {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    
    .window-video {
      height: 220px;
      width: 350px;
      border-radius: 10px;
      margin-top: 10px;
    }
    
    .window-button {
      display: flex;
      justify-content: center;
      margin-top: 20px;
    }
    
    .window-button button {
      width: 250px;
      border: none;
      height: 50px;
      border-radius: 25px;
      background-color: #6A58C6;
      color: white;
      font-size: 30px;
      cursor: pointer;
    }
    
    .project-buttons {
      display: flex;
      flex-direction: column;
      justify-content: center; /* Adjusted to center the buttons vertically */
    }
    
    .project-button {
      height: 80px;
      font-size: 20px;
      width: 270px;
      border-radius: 25px;
      border: none;
      margin-bottom: 10px;
      cursor: pointer;
    }
    
    .project-button:nth-child(1) {
      background-color: #6A58C6;
    }
    
    .project-button:nth-child(2) {
      background-color: #7AFFC9;
    }
    
    .project-button:nth-child(3) {
      background-color: #FFBD59;
    }
    
    .project-button:nth-child(4) {
      background-color: #7AFFC9;
    }
    
    .project-button:nth-child(5) {
      background-color: #6A58C6;
    }
    .boxes-screen {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #6A58C6;
      height: 40px;
      width: 200px;
      font-size: 20px;
      border-radius: 20px;
      margin-bottom: 10px;
      cursor: pointer;
      color: white;
      transition: background-color 0.3s ease; /* Added transition effect */
    }
    .after-login-screen{
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
    }
    .after-login-left-container{
      display: flex;
      flex-direction: column;
      margin-top: 20px;
    }
    .after-login-left-container button{
      
      height: 60px;
      font-size: 15px;
      width: 150px;
      border-radius: 25px;
      box-shadow: 5px 5px 5px #888888;
      border-radius: 25px 0 0 25px;
      border: none;
      cursor: pointer;
    }
    .after-login-right-container{
      margin-top: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 550px;
      width: 200px;
      background-color: #DEDEDE;
      border-radius: 25px;
      border: 5px solid black;
    }
    .selected-step {
      background-color: #FFBD59; 
      color: white; 
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); 
    }
  }