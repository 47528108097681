.selected {
  position: relative;
}

.selected:after {
  content: '';
  position: absolute;
  bottom: -20px; /* Adjust the distance of the triangle from the button */
  left: 40%;
  /* transform: translateX(-50%); */
  transform: rotate(180deg);
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #fff; /* Adjust the color and size of the triangle */
}

.sidemenu_h1{
  font-size: 20px;
  text-align: left;
  font-family: 'Poppins', Courier, monospace;
  color: #fff; 
}
.sidemenu_toolbar_h1{
  font-size: 20px;
  text-align: center;
  font-family: 'Poppins', Courier, monospace;
  color: #000; 
}

.sidemenu_p{
  font-size: 10px;
  text-align: center;
  font-family: 'Courier New', Courier, monospace;
  color: #fff;
}

.custom-player {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; /* Aspect ratio 16:9 for video */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;
  background-color: violet;
  border: none;
  border-radius: 50%;
  cursor: pointer;
}


