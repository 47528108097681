@import url("https://fonts.googleapis.com/css2?family=Bitter&family=Mate+SC&family=Raleway&family=Roboto:wght@500&family=Source+Sans+Pro:wght@300;600&family=Squada+One&display=swap");




.main-div {

padding: 32px 24px;
    background-color: rgb(255, 255, 255);
    border: 2px solid #F3F2F2;
        border-radius: 0.5rem;
    margin: 30px 0px 0px;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
        rgba(17, 17, 26, 0.1) 0px 0px 8px;
        /* border: 1px solid red; */
}

.main-div h2 {
    color: #0A0103;
        font-family: "Poppins";
        font-size: 25px;
        font-weight: 700;
}

.main-heading {
    display: flex;
    margin-top: 30px;
    align-items: center;
    padding: 20px 0 20px 20px;
    background: #f1f4ff;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    /* border: 1px solid red; */
}

.main-heading:first-child {
    margin-top: 0;
}

.main-heading h3 {
    margin-left: 30px;
    font-family: "Mate SC", serif;
    color: #303243;
    font-family: "Raleway", sans-serif;
    word-spacing: 1px;
    letter-spacing: 1px;
    font-weight: 600;
    font-size: 18px;
}

.main-heading p {
    cursor: pointer; 
    width: 1.7rem;
    height: 1.7rem;
    border-radius: 50%;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
        rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
    background: #fff;
    padding: 0.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 1px solid red; */
}

.answers {
    padding: 30px 20px 40px 20px;
    font-family: "Raleway", sans-serif;
    font-size: 15px;
    word-spacing: 2px;
    font-weight: 400;
    line-height: 1.7;
    background: #fcf5f5;
    color: rgb(40, 37, 53);
}