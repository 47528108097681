/* Center the video on the page */
.video-demo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90vh; /* Occupies most of the viewport height */
    background-color: #f9f9f9; /* Optional background color */
    padding: 1rem;
    box-sizing: border-box;
    margin-top: 140px; /* Adjusted for any additional top margin */
  }
  
  /* Wrapper to maintain a rectangular aspect ratio */
  .video-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 800px; /* Maximum width for larger screens */
    height: auto;
    aspect-ratio: 16 / 9; /* Ensures the video remains rectangular */
  }
  
  /* Responsive video iframe */
  .responsive-video {
    width: 100%; /* Full width of the wrapper */
    height: 100%; /* Full height of the wrapper */
    border: none; /* Removes the iframe border */
  }
  