.testimonials-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .scroll-group {
    display: flex;
    overflow-x: auto;
    scroll-behavior: smooth;
    gap: 10px;
  }
  
  .scroll-group::-webkit-scrollbar {
    display: none;
  }
  
  .scroll-group-1 {
    animation: scroll-left 20s linear infinite;
  }
  
  .scroll-group-2 {
    animation: scroll-right 20s linear infinite;
  }
  
  .scroll-group-3 {
    animation: scroll-left 20s linear infinite;
  }
  
  .card-container {
    flex: 0 0 auto; 
    width: 300px; 
    height: '100%'
  }
  
  @keyframes scroll-left {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc(-100% + 100vw));
    }
  }
  
  @keyframes scroll-right {
    0% {
      transform: translateX(calc(-100% + 100vw));
    }
    100% {
      transform: translateX(0);
    }
  }
  
  @media (max-width: 768px) {
    .scroll-group {
      flex-direction: row;
      gap: 20px;
      overflow: auto;
      width: 100%;
    }
  
    .scroll-group-1 {
        animation: scroll-right 60s linear infinite;
        animation-delay: 0ms;
      }
      
      .scroll-group-2 {
        animation:  scroll-left 60s linear infinite;
        animation-delay: 0ms;
      }
      
      .scroll-group-3 {
        animation: scroll-right 60s linear infinite;
        animation-delay: 0ms;
      }
  }
  