.divContainerr {
    height: 80px;
    width: 200px;
    /* box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
     */
    margin: 5px
}
.divContainer:hover {
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}
  
.carousel-imagee {
    height: 80pxpx;
    width: 100%;
    /* border-radius: 30px; */
}
.PictureSectionnn {
    position: relative;
    width: 100%;
    height: 80px;
    overflow: hidden;
    margin-top: 1rem;
}

  
  .pictureContainerr {
    width:100%;
    overflow: hidden;
    position: relative;
  }
  
  .pictureMarqueeee {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .nextButton {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    padding: 10px 15px;
    cursor: pointer;
    font-size: 16px;
    background-color: #6a58c6;
    border-radius: 50%;
    color: '#fff';
    border: 0;
  }
  @media screen and (max-width: 500px)  {
    .pictureMarqueeee {
        width: 10000px;
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        animation-name: marqueeAnimation;
        animation-duration: 120s;
        animation-iteration-count: infinite;
        animation-play-state: running;
        animation-direction: reverse;
        animation-delay: 0ms;
        animation-timing-function: linear;
      }
  }
  @keyframes marqueeAnimation {
    0% {
      transform: translateX(-1500px);
    }
  }
