.details_main_prompt {
    background-color: white;
    /* width: 100%; */
    /* overflow: hidden; */
    padding: 0 22px;
    position: relative;
    /* width: 96vw; */
}

.sidebar2{
    height: 304px;
    height: fit-content;
    width: 280px;
    padding: 40px;
     display: block;
}
.left_box_of_data{
    width: 90%; 
     margin-left: 2rem;
     /* border: 1px solid red; */
    
}

.sidebar {
    /* height: 304px; */
    position: fixed;
    height: fit-content;
    width: 15%;
    padding: 40px;
   top: 15%;
    left: 10;
    z-index: 9999;
    box-shadow: rgba(0, 0, 0, 0.1) 0px -2px 11px -2px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    border-radius: 1.5rem;

}

.sidebar .sidebar_heading {
    color: #21191B;
    font-size: 16px;
    font-family: Poppins,
        ui-sans-serif,
        system-ui,
        -apple-system,
        BlinkMacSystemFont,
        Segoe UI,
        Roboto,
        Helvetica Neue,
        Arial,
        Noto Sans,
        sans-serif,
        Apple Color Emoji,
        Segoe UI Emoji,
        Segoe UI Symbol,
        Noto Color Emoji;
    padding: 20px 0px;
    font-weight: 700;
    text-align: start;
    border-bottom: 2px solid rgba(225, 225, 225, 0.962);

}
.headingp {
    padding: 120px 120px 0 120px;
    /* border: 1px solid red; */
  
}
.headingp h1 {
    height: auto;
    color: #0A0103;
    font-size: 48px;
    font-family: "Poppins", sans-serif;
    margin: 0px 0px 24px;
    text-align: left;
    margin-left: 22rem;

}

.headingp p {
    color: #000;
    font-size: 22px;
    font-family: "Open Sans", sans-serif;
    text-align: left;
    margin-left: 22rem;
    word-wrap: break-word;
    box-sizing: border-box;
}
.headingp .yellow-box{
    margin-left: 22rem;

}
.buttons-promtl{
    margin-left: 22rem;
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    gap:20px;
}
.button-promptl-enroll{
    width: 35%;
    background-color:#6a58c6;
}
.button-promptl-download{
    width: 35%;
    border-color: #6a58c6;
    color: #6a58c6;
}

.batch-box {
    margin-left: 22rem;
    border: 1px solid red;
    margin-top: 40px;
    width: 30%; 
    border-radius: 10px;
    box-sizing: border-box; 
    display: flex;
    flex-direction: row;
    padding: 10px;
}
.batch-box-2 {
    border: 1px solid red;
    margin-top: 40px;
    width: 30%; 
    border-radius: 10px;
    box-sizing: border-box; 
    display: flex;
    flex-direction: row;
    padding: 10px;
}
.outcome-box{
    margin-left: 22rem;
    padding: 20px 120px 20px 120px;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
    rgba(17, 17, 26, 0.1) 0px 0px 8px;
    border-radius: 20px;
}
.certificate-box{
    margin-left: 22rem;
    padding: 20px 120px 20px 120px;
    background: linear-gradient(to bottom,  #6a58c6 60%, white 60%);
    margin-top: 20px;
}
.certificate-box h2{
    color: #fff;
    font-size: 32px;
    font-family: "Poppins", sans-serif;
    margin: 0px 0px 24px;
    text-align: center;
}
.certificate-box p {
    color: #fff;
    font-size: 22px;
    font-family: "Open Sans", sans-serif;
    text-align: left;
    word-wrap: break-word;
    text-align: center;
    box-sizing: border-box;
}

.buttons-certificate-box{
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
}
.button-certificate-box{
    background: linear-gradient(to top left, #ffffffff,#ffffff33);
    width: 200px;
    height: 50px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 900;
    cursor: pointer;
}
.certificate-img{
    width: 90%;
}
.cert-image-container{
    margin-top: 20px;
    /* background: linear-gradient(to top, white 50%, #6a58c6 50%); */
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.outcome-box h3 {
    height: auto;
    color: #0A0103;
    font-size: 32px;
    font-family: "Poppins", sans-serif;
    margin: 0px 0px 24px;
    text-align: left;
}
.tick-boxes-prompt{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}
.tick-boxes-prompt-box2{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}
.tick-boxes-prompt-box1{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}
.tick-boxes-prompt-box3{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;
}
.download-syllabus-button {
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
}
.list-div-container{
    margin-top: 50px;
    margin-left: 22rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.concentric-bullet-ul {
    list-style-type: none;
    padding: 30;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.concentric-bullet {
    position: relative;
    margin-left: 20px;
    padding-left: 30px;
    line-height: 1.5em;
    font-size: 18px;
    font-weight: bold;

}

.concentric-bullet:before,
.concentric-bullet:after {
    content: "";
    position: absolute;
    left: 0;
    top: 15%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    border-radius: 50%;
    z-index: 1;
}

.concentric-bullet:before {
    background-color: #6a58c6; 
}

.concentric-bullet:after {
    background-color: white; 
    width: 15px; 
    height: 15px; 
    z-index: 2;
}
.vsc-students{
    margin-left: 22rem;
    margin-top: 60px;
}
.vsc-students h1{
    color: #000;
    font-size: 30px;
    font-family: "Poppins", sans-serif;
    margin: 0px 0px 24px;
    text-align: center;
}
.pathway-prompt{
    margin-left: 22rem;
    margin-top: 60px;
}
.pathway-prompt h2{
    color: #000;
    font-size: 32px;
    font-family: "Poppins", sans-serif;
    margin: 0px 30px 24px;
    text-align: left;
}
.pathway-prompt p {
    font-size: 22px;
    font-family: "Open Sans", sans-serif;
    text-align: left;
    word-wrap: break-word;
    box-sizing: border-box;
    margin: 0px 30px 24px;

}
.pathway-container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  
  .pathway-box {
    width: 80%; 
    height: 100%; 
    margin: 10px; 
    display: flex;
    flex-direction: column;
    padding: 20px;
    color: white; /* Adjust text color as needed */
  }
  .step-pathway {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 20px; /* Adjust the margin between dots and boxes as needed */
  }
  .vertical-dots {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 180px;
    margin: 20px 0; 
  }
  
  .dot {
    width: 40px; 
    height: 40px;
    background-color: #6a58c6; 
    border-radius: 50%;
    margin: 10px 0; 
    position: relative;
  }
  .dot-span {
    width: 25px; 
        height: 25px;
        background-color: #000; /* Violet color */
        border-radius: 50%;
        margin-right: 10px; /* Add some right margin for separation */
        display: inline-block; /* Use inline-block to allow margin */
  }
  
  .dot:not(.no-line)::before {
    margin-top: 0;
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: 10px; /* Adjust the width of the line */
    height: 240px; /* Adjust the height of the line */
    background-color: #6a58c6; /* Violet color */
  }
  .tools-box-prompt{
    margin-left: 23rem;
    margin-top: 60px;
  }
  .tools-box-prompt h2{
    color: #000;
    font-size: 32px;
    font-family: "Poppins", sans-serif;
    margin: 0px 30px 24px;
    text-align: left;
}
 .image-container-prompt{
    display: flex;
    flex-direction: row;
    gap: 50px;
    justify-content: flex-start;
    margin: 30px;
 }
 .suitable-for-prompt{
    margin-left: 23rem;
    margin-top: 60px;

 }
 .suitable-for-prompt h2{
    color: #000;
    font-size: 32px;
    font-family: "Poppins", sans-serif;
    margin: 0px 30px 24px;
    text-align: left;
}
.borderbox-for-prompt {
    margin-left: 23rem;
    margin-top: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
}
 .borderbox-for-prompt  h2{
    color: #000;
    font-size: 32px;
    font-family: "Poppins", sans-serif;
    margin: 0px 30px 24px;
    text-align: left;
}
 .suitable-for-prompt p {
        color: #000;
        font-size: 22px;
        font-family: "Open Sans", sans-serif;
        text-align: left;
        word-wrap: break-word;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        margin: 10px;
 }
 .suitable-for-prompt-ul {
    list-style-type: disc; 
    color: black; 
    font-size: larger;
    margin-left: 40px;
 }
 .border-boxes-row-prompt{
    display: flex;
    flex-direction: row;
    gap: 20px;
 }
 .faq-box-prompt{
    margin-left: 23rem;
    margin-top: 60px; 
    margin-bottom: 30px;
 }
 .faq-box-prompt h2{
    color: #000;
    font-size: 32px;
    font-family: "Poppins", sans-serif;
    margin: 0px 30px 24px;
    text-align: left;
}
@media screen and (max-width:1448px) {
    .left_box_of_data{
        width: 75%; 
        
        /* border: 1px solid red; */
       
    }
  
  
}
@media screen and (max-width:850px){
    .headingp {
        padding: 10px;
        padding-top: 120px;
        /* border: 1px solid red; */
      
    }
    .box_of_data{
        width:auto ;
        margin: 0;
        padding: 0px;
    }
    .left_box_of_data {
        /* border: 1px solid red; */
       width: 100%;
       margin:0;
      }
      .headingp h1{
        margin-left: 0;
        /* border:1px solid red; */
       margin-top: 90px;
       width: 100%;
    }
    .headingp p{
        margin-left: 0;
        font-size: 24px;
       width: 100%;

    }
    .headingp .yellow-box{
        margin-left: 0;
       width: 100%;

    
    }
    .headingp {
        padding-top: 0px;
        /* border: 1px solid red; */
    }
    .buttons-promtl{
        margin-left: 0;
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        /* justify-content: space-between; */
    }
    .button-promptl-enroll{
        width: 96%;
        background-color:#6a58c6;
        margin-bottom: 20px;
    }
    .button-promptl-download{
        width: 96%;
        border-color: #6a58c6;
        color: #6a58c6;
    }
    .heading h1 {
        height:auto;
        font-size: 40px;
        margin-top: 4rem;
        /* border: 1px solid red; */
 
    }
    .batch-box {
        margin-left: 0;
        border: 1px solid #000;
        margin-top: 40px;
        width: 96%; 
        border-radius: 10px;
        box-sizing: border-box; 
        display: flex;
        flex-direction: row;
        padding: 10px;
    }
    .batch-box-2 {
        margin-left: 0;
        border: 1px solid red;
        margin-top: 40px;
        width: 96%; 
        border-radius: 10px;
        box-sizing: border-box; 
        display: flex;
        flex-direction: row;
        padding: 10px;
        height: 100px;
    }
    .tick-boxes-prompt{
        width: 100%;
        display: flex;
        /* align-items: center;
        justify-content: center; */
        flex-direction: column;
    }
    .tick-boxes-prompt-box2{
        width: 100%;
    }
    .tick-boxes-prompt-box1{
        width: 100%;
    }
    .tick-boxes-prompt-box3{
        width: 100%;
    }    
    .outcome-box{
        margin-left: 0;
        padding: 10px;
    }
    .certificate-box{
        margin-left: 0;
        padding: 10px;
        background: linear-gradient(to bottom,  #6a58c6 80%, white 80%);

    }
    .download-syllabus-button {
        justify-content: center;
    }
    .buttons-certificate-box{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        gap:5px;
    }
    .concentric-bullet-ul {
        list-style-type: none;
        padding: 30;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 20px;
    }
    .list-div-container{
        margin-left: 0;
    }
    .vsc-students{
        margin-left: 0;
    }
    .pathway-prompt{
        margin-left: 0;
    }
    .pathway-box {
        width: 90%; 
        height: 100%; 
        margin: 10px; 
        display: flex;
        flex-direction: column;
        padding: 10px;
        color: white; /* Adjust text color as needed */
      }
      .vertical-dots {
        display: none;
      }
      .tools-box-prompt{
        margin-left: 0;
      }
      .suitable-for-prompt{
        margin-left: 0;
        margin-top: 60px;
     }
      .image-container-prompt{
        display: flex;
        flex-direction: row;
        gap: 20px;
        justify-content: flex-start;
        margin: 30px;
     }
     .borderbox-for-prompt {
        margin-left: 0;
        margin-top: 60px;
    }
    .border-boxes-row-prompt{
        display: flex;
        flex-direction: column;
        gap: 20px;
     }
     .faq-box-prompt{
        margin-left: 0;
        margin-top: 60px; 
     }
     .suitable-for-prompt p {
        color: #000;
        font-size: 22px;
        font-family: "Open Sans", sans-serif;
        text-align: left;
        word-wrap: break-word;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        margin: 5px;
 }

}