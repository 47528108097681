@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600&family=Poppins:wght@600;700&display=swap');

.main_myDeshboard{
    background-color: #ffffff;
        width: 100%;
      
        overflow: hidden;
}
.drawer{
             
     height: 100vh;
    position: fixed;
     width: 16.5rem;
     box-shadow: rgb(217, 217, 217) -1px 0px 0px 0px inset;
     padding: 0%;
       
}
.deshboard_items{
    /* border: 1px solid red; */
    margin-top: 13px;
    height: 56px;
    padding: 16px 8px 16px 24px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.deshboard_items p{
    color: #1A202c;
    font-family: "Open Sans", sans-serif;
    font-size: 20px;
    font-weight: 600;
}

.my_courses .my_courses_name{
    color: #1A202C;
    padding: 10px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* border: 1px solid red; */
}

.my_courses:hover,
.my_courses:active {
    background-color: #f2f6ff;
    cursor: pointer;
    box-shadow: rgb(52, 112, 228) 4px 0px 0px 0px inset;
    color: #3470e4 !important;
}

.my_courses:hover .my_courses_name,
.my_courses:active .my_courses_name {
    color: #3470e4 !important;
}



/* **************************** */

 .kickstart_courses_vsc {
     /* border: 1px solid red; */
     width: 100%;
     /* height: 100%; */
     margin: 30px 0px 0px;
     padding: 32px 24px;

 }

 .kickstart_courses_vsc h2 {
     color: #0A0103;
     font-family: "Poppins";
     font-size: 28px;
     font-weight: 700;

     /* border: 1px solid red; */
 }

 .level_courses_vsc {
     display: flex;
     /* margin-right: 10px; */
     margin-top: 3rem;
     display: flex;
     justify-content: center;
     /* border: 1px solid red; */
 }

 .course_1_vsc {
     /* border: 1px solid red; */

     box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.05);
     width: 30%;
     border: 1px solid rgb(229, 228, 228);
     margin-right: 4rem;
     border-radius: 18px;
     margin-left: -1rem;
 }

 .course_2_vsc {
     box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.05);
     width: 30%;
     border: 1px solid rgb(229, 228, 228);
     margin-right: 4rem;
     border-radius: 18px;
     margin-left: -1rem;
 }

 .course_3_vsc {
     box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.05);
     width: 30%;
     border: 1px solid rgb(229, 228, 228);
     border-radius: 18px;
     /* border: 1px solid red; */
     margin-left: -1rem;
 }

 .course_1_vsc .course_data {
     width: 93%;
     /* border: 1px solid red; */
     min-height: 15rem;
     margin: 10px;
 }


 .course_2_vsc .course_data {
     width: 93%;
     /* border: 1px solid red; */
     min-height: 15rem;
     word-wrap: break-word;
     margin: 10px;
 }

 .course_3_vsc .course_data {
     width: 93%;
     /* border: 1px solid red; */
     min-height: 15rem;
     margin: 10px;
 }

 .course_1_vsc .course_1_button { 
     /* border: 1px solid red; */
     margin: 35px 0px 0px 0px;
     display: flex;
     justify-content: flex-end;
     padding: 8px 10px;
 }

 .course_2_vsc .course_2_button {
     /* border: 1px solid red; */
     margin: 35px 0px 0px 0px;
     display: flex;
     justify-content: flex-end;
     padding: 8px 10px;
 }

 .course_3_vsc .course_3_button {
     /* border: 1px solid red; */
     margin: 35px 0px 0px 0px;
     display: flex;
     justify-content: flex-end;
     padding: 8px 10px;
 }







 .course_1_vsc .course_1_button button {
     padding: 12px 16px;
     font-size: 14px;
     font-family: "Open sans", sans-serif;
     background-color: #ED0331;
     color: #FFFFFF;
     border-radius: 8px;
     border: none;
     display: flex;
     align-items: center;
     justify-content: center;
     text-transform: uppercase;
     text-align: center;
     letter-spacing: 0.7px;
 }

 .course_1_vsc .course_1_button button:hover {
     cursor: pointer;
     background-color: #dc2626;
 }

 .course_1_vsc .course_1_button .details {
     padding: 12px 16px;
     font-size: 14px;
     font-family: "Open sans", sans-serif;
     background-color: #FFFFFF;
     color: #ED0331;
     font-weight: 600;
     border-radius: 8px;
     border: 1px solid red;
     margin-right: 0.6rem;
     display: flex;
     align-items: center;
     justify-content: center;
     text-transform: uppercase;
     text-align: center;
     letter-spacing: 0.7px;
 }

 .course_1_vsc .course_1_button .details:hover {
     cursor: pointer;
     background-color: rgba(254, 242, 242, 1);
 }

 .course_2_vsc .course_2_button button {
     padding: 12px 16px;
     font-size: 14px;
     font-family: "Open sans", sans-serif;
     background-color: #ED0331;
     color: #FFFFFF;
     border-radius: 8px;
     border: none;
     display: flex;
     align-items: center;
     justify-content: center;
     text-transform: uppercase;
     text-align: center;
     letter-spacing: 0.7px;
 }

 .course_2_vsc .course_2_button button:hover {
     cursor: pointer;
     background-color: #dc2626;
 }

 .course_2_vsc .course_2_button .details {
     padding: 12px 16px;
     font-size: 14px;
     font-family: "Open sans", sans-serif;
     background-color: #FFFFFF;
     color: #ED0331;
     font-weight: 600;
     border-radius: 8px;
     border: 1px solid red;
     margin-right: 0.6rem;
     display: flex;
     align-items: center;
     justify-content: center;
     text-transform: uppercase;
     text-align: center;
     letter-spacing: 0.7px;
 }

 .course_2_vsc .course_2_button .details:hover {
     cursor: pointer;
     background-color: rgba(254, 242, 242, 1);
 }


 .course_3_vsc .course_3_button button {
     padding: 12px 16px;
     font-size: 14px;
     font-family: "Open sans", sans-serif;
     background-color: #ED0331;
     color: #FFFFFF;
     border-radius: 8px;
     border: none;
     display: flex;
     align-items: center;
     justify-content: center;
     text-transform: uppercase;
     text-align: center;
     letter-spacing: 0.7px;
 }

 .course_3_vsc .course_3_button button:hover {
     cursor: pointer;
     background-color: #dc2626;
 }

 .course_3_vsc .course_3_button .details {
     padding: 12px 16px;
     font-size: 14px;
     font-family: "Open sans", sans-serif;
     background-color: #FFFFFF;
     color: #ED0331;
     font-weight: 600;
     border-radius: 8px;
     border: 1px solid red;
     margin-right: 0.6rem;
     display: flex;
     align-items: center;
     justify-content: center;
     text-transform: uppercase;
     text-align: center;
     letter-spacing: 0.7px;
 }

 .course_3_vsc .course_3_button .details:hover {
     cursor: pointer;
     background-color: rgba(254, 242, 242, 1);
 }





 /* ********************** */
 /* ********************** */


 .referral{
    border-bottom: 1px solid gray;
            width: 100%;
            height: 6rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            /* border:1px solid red */
 }
  .referral h2{
     /* border: 1px solid red; */
        width: 122px;
            height: 47px;
            margin: 1rem 0rem 1rem 3rem;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: Gilroy;
            font-weight: 700;
            font-size: 34px;
            line-height: 48px;
            color: rgba(0, 0, 0, 0.88);
  }
  .referral-box{
    display: flex;
    /* border: 1px solid red; */
    margin-left: 2rem;
  }

  .referral-box-1{
    width: 307px;
            height: 92px;
            padding: 17px 24px;
            border-right: 1px solid gray;
            border-left: 1px solid gray;
  }
.referral-box-1-1{
    display: flex;
    align-items: center;
}

.referral-box-1-1-1{
    margin-left: 1rem;
}
.referral-box-1-1-1 p{
font-family: Gilroy;
        font-weight: 500;
        font-size: 16px;
        color: #141414;
        line-height: 21px;
}
.referral-box-1-1-1-1{
    font-family: Gilroy;
            font-weight:800;
            line-height: 32px;
            font-size: 34px;
            margin-top: 0.6rem;
            color: rgba(0, 0, 0, 0.88);
}

.referral-box-2{
    width: 307px;
            height: 92px;
            padding: 17px 24px;
            border-right: 1px solid gray;
            border-left: 1px solid gray;
}

.referral-box-2-1{
    display: flex;
        align-items: center;
}
.referral-box-2-1-1{
    margin-left: 1rem;
}
.referral-box-2-1-1 p {
    font-family: Gilroy;
    font-weight: 500;
    font-size: 16px;
    color: #141414;
    line-height: 21px;
}

.referral-box-2-1-1-1 {
    font-family: Gilroy;
    font-weight: 800;
    line-height: 32px;
    font-size: 34px;
    margin-top: 0.6rem;
    color: rgba(0, 0, 0, 0.88);
}
.referral-box-3{
    width: 307px;
        height: 92px;
        padding: 17px 24px;
}
.referral-box-3-1{
        display: flex;
        align-items: center;    
}
.referral-box-3-1-1{
    margin-left: 1rem;
        /* border: 1px solid red; */
}
.referral-box-3-1-1 p {
    font-family: Gilroy;
    font-weight: 500;
    font-size: 16px;
    color: #141414;
    line-height: 21px;
}
.referral-box-3-1-1-1{
    font-family: Gilroy;
        font-weight: 800;
        line-height: 32px;
        font-size: 34px;
        margin-top: 0.6rem;
        color: rgba(0, 0, 0, 0.88);
}

.referral-box-4{
  /* border: 1px solid red; */
        padding: 20px 0px 100px;
}

.left-box-1{
    display: flex;
    align-items: center;
}
.link{
    background-color: #ffffff;
            margin: 6px 0px 0px;
            padding: 6px;
            height: 44px;
            border: 1px solid gray;
            border-radius: 0.3rem;
            display: flex;
            justify-content: space-between;
}
.link-input{
            border: none;
            width: 100%;
            outline: none;
            font-size: 16px;
            font-weight: 400;
            color: rgb(0, 0, 0, 0.88);
}
.share-button{
    background: #000000;
            color: #fff;
            border-radius: 0.3rem;
            width: 100%;
            border: none;
            padding: 10px 15px;
            font-size: 16px;
            border: 1px solid gray;
            display: flex;
            align-items: center;
            margin-top: 1rem;
            justify-content: center;
}
 @media screen and (max-width:1400px){
    .referral {
            border-bottom: 1px solid gray;
            width: 100%;
            height: 18rem;
            align-items: flex-start;
         
        }
.referral-box {
        flex-direction: column;
    }
     .referral-box-4{
            margin-top: 3rem;
            width: 100%;
     }



 }
 @media screen and (max-width:1300px) {
     .level_courses_vsc {
         flex-direction: column;
         /* border: 1px solid red; */
     }

     .course_1_vsc {
         width: 100%;
     }

     .course_2_vsc {
         width: 100%;
         margin-top: 2rem;
     }

     .course_3_vsc {
         width: 100%;
         margin-top: 2rem;
     }

    
 }

  @media screen and (max-width:540px){
    .referral {
            flex-direction: column;
            height: 100%;
         
        }

        .referral-box-4{
            margin-top: 2rem;
        }
.referral-box{
    width:90%;
    margin-left: 5%;

}
        .referral-box-1{
            width: 100%;
        }
   .referral-box-2 {
                      width: 100%;
                  }
         .referral-box-3 {
                    width: 100%;
              }

  }

        @media screen and (max-width:490px){
            .link-input{
               
                word-wrap: break-word;
                font-size: 15px;
                width:'100%'
            }
        }