.mkw_cards_wrapper {
    display: flex;
    align-items:center;
    justify-content:center;
    flex-wrap: wrap;
    /*margin: 0px 70px;*/
  }
  
  h5 {
    font-size: 1.25rem;
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
  }
  .mkw_card {
    position: relative;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
    width: 22rem !important;
    height: 27rem !important;
    display: inline-table;
    /*margin: 20px;*/
    /*margin-top: 10px;*/
  }
  
  
  .mkw_test_apply_btn {
    bottom: 20px;
    left: 70px;
    display: inline;
    width: 200px;
    height: 55px;
    background: linear-gradient(to top left, #52efda 0%, #8dffcc 100%) !important;
    border: none;
    font-weight: 900;
    font-size: 1rem;
    font-family: "Spartan", sans-serif !important;
    font-stretch: ultra-expanded;
    cursor: pointer;
    padding: 0px 20px;
  }
  
  .mkw_card-img-top {
    border-top-left-radius: calc(0.25rem - 1px);
    border-top-right-radius: calc(0.25rem - 1px);
    min-height: 195px;
    max-height: 195px;
    width: 100%;
  }
 
  .subbtnbox Button{
    background-color: #6a58c6;
  }
  .mkw_heading {
    text-align: center;
    font-weight: bold;
    margin: 20px 20% 20px 20%;
  }
  body {
    background-color: #fff;
  }
  .card-actions{
    padding: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;

  
  }
  .video{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 86vh;
        overflow: hidden;
        padding-bottom: 1%;
  
  }
    .video_container{
            width: fit-content;
            height: fit-content;
            display: flex;
            overflow: hidden;
            border-radius: 30px;
  }
  .video_iframe{
    width: 800px;
    height: 450px;
  }
  @media screen and (max-width: 1280px) {
     .video{
        height: 100%;
        padding-top: 150px !important;
        padding-left: 5%;
        padding-right: 5%;
        padding-bottom: 200px;
        overflow: hidden;
     }
  }
  @media screen and (max-width: 480px){
    .mkw_cards_wrapper{
      margin-left: 0px !important;
      padding-left: 20px;
      align-items: center;
    }
  }